import { useEffect, useState } from "react";

// material ui
import {
  Button,
  Container,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

// third party libraries
import { Formik } from "formik";
import * as Yup from "yup";

// project import
import SnackBar from "../../components/Snackbar";
import { formatPhoneNumber } from "../ValidationPage";
import axios from "axios";
import common from "../../config.json";
import { useAuthUser } from "react-auth-kit";

// ===================== PATIENT MY DETAILS PAGE ===================== //
const MyAccountDetailsPage = () => {
  const authUser: any = useAuthUser();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    id: "",
    companyName:"",
  });
  console.log(">>",authUser());
  const token = authUser()?.token;
  const userId = authUser()?.id;
 
  useEffect(() => {
    getUserData();
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen(false);
  };

  const getUserData = async () => {
    let baseUrl = common.BASE_URL + common.USER_API_URL + `/${userId}`;
    
    try {
      const response = await axios.get(baseUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
       const userData = response.data.user;
      const transformedData = {
        firstName: userData.first_name,
        lastName: userData.last_name,
        phone: userData.phone_number,
        email: userData.email,
        id: userData.id.toString(),
        companyName: userData.company_name,
      };

      setData(transformedData);

      console.log(">>>", data);
    } catch (error: any) {
      console.log(error.message);
    }
    console.log(">>>", data);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...data }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .matches(/^[A-Za-z]+$/, "Enter proper first name")
          .required("First Name is required"),
        lastName: Yup.string()
          .matches(/^[A-Za-z]+$/, "Enter proper last name")
          .required("Last Name is required"),
        phone: Yup.string()
          .required("Mobile Phone number required")
          .min(10)
          .max(10),
        email: Yup.string()
          .email("Enter a valid email")
          .required("Email is required"),
        companyName: Yup.string().required("Company name is required"),
      })}
      onSubmit={async (values) => {
        console.log(values);
        const data = { ...values };
        let baseUrl = common.BASE_URL + common.USER_API_URL + "/update";

        try {
          const response = await axios
            .put(baseUrl, data, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then(function (response) {
              if (response.data) {
                setOpen(true);
                setType("success");
                setMessage("You have successfully Updated records.");
              }
            });
          console.log(response);
        } catch (error: any) {
          setOpen(true);
          setType("error");
          setMessage(error.response.data.message || error.statusText);
          
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Container maxWidth={false} disableGutters={false}>
            <Grid container spacing={0} maxWidth="850px">
              <Paper
                elevation={0}
                className="page-container-paper"
                sx={{
                  borderRadius: "16px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">My Details</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="email"
                      label="Email"
                      fullWidth
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                      disabled
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error id="email-helper-text">
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      required
                      id="mobile-phone"
                      label="Mobile Phone"
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      value={formatPhoneNumber(values.phone? values.phone:"")}
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.phone && errors.phone)}
                    />
                    {touched.phone && errors.phone && (
                      <FormHelperText error id="mobile-phone-helper-text">
                        {errors.phone}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      required
                      id="first-name"
                      label="First Name"
                      fullWidth
                      value={values.firstName}
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.firstName && errors.firstName)}
                    />
                    {touched.firstName && errors.firstName && (
                      <FormHelperText error id="first-name-helper-text">
                        {errors.firstName}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      required
                      id="last-name"
                      label="Last Name"
                      fullWidth
                      value={values.lastName}
                      name="lastName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.lastName && errors.lastName)}
                    />
                    {touched.lastName && errors.lastName && (
                      <FormHelperText error id="last-name-helper-text">
                        {errors.lastName}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      required
                      id="companyName"
                      label="Company Name"
                      fullWidth
                      value={values.companyName}
                      name="companyName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.companyName && errors.companyName)}
                    />
                    {touched.companyName && errors.companyName && (
                      <FormHelperText error id="companyName-helper-text">
                        {errors.companyName}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      className="custom-btn"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
                {open ? (
                  <SnackBar
                    onClose={handleClose}
                    data2={type}
                    data3={message}
                  />
                ) : (
                  ""
                )}
              </Paper>
            </Grid>
          </Container>
        </form>
      )}
    </Formik>
  );
};

export default MyAccountDetailsPage;
