
 const selectStyles = (data:any) => {
     const css4: any = {
       page: {
         flexDirection: "row",
         backgroundColor: "#FFF", // Set background color to white for a clean look
         padding: 20,
         fontWeight: "bold",
       },
       section: {
         margin: 10,
         padding: 10,
         flexGrow: 2,
       },
       header: {
         marginBottom: 20,
         textAlign: "center",
         fontSize: 16,
         fontWeight: "bold",
       },
       billTime: {
         marginBottom: 10,
       },
       companyInfo: {
         marginBottom: 20,
         fontSize: 12,
         flexDirection: "row",
       },
       recipientInfo: {
         marginLeft: "auto",
         fontSize: 12,
       },
       itemList: {
         marginBottom: 20,
         fontSize: 12,
       },
       tableHeader: {
         flexDirection: "row",
         borderTopWidth: 0.5,
         borderTopColor: "#000",
         alignItems: "center",
         padding: 10,
         fontWeight: "bold",
         color: data.fontColor,
         backgroundColor: "#f2f2f2",
       },

       tableRow: {
         backgroundColor:
           data.backgroundColor === "none" ? "#fff" : data.backgroundColor,
         flexDirection: "row",
         alignItems: "center",
         padding: 5,
         marginBottom: 5,
       },
       totalRow: {
         flexDirection: "row",
         justifyContent: "space-between", // Align items with space between
         borderTopWidth: 0.5,
         borderTopColor: "#000",
         padding: 10,
         width: "30%", // Adjust width as needed
         alignSelf: "flex-end", // Align the total row to the end of the section
       },
       totalRow2: {
         flexDirection: "row",
         justifyContent: "space-between", // Align items with space between
         padding: 10,
         width: "30%", // Adjust width as needed
         alignSelf: "flex-end", // Align the total row to the end of the section
         borderWidth: 0.5,
         borderColor: "#000",
       },

       logo: {
         marginBottom: 10,
         width: 100, // Adjust width and height as needed
         height: 50,
       },
     };
     const css3: any = {
       page: {
         flexDirection: "row",
         backgroundColor: "#FFF", // Set background color to white for a clean look
         padding: 20,
       },
       section: {
         margin: 10,
         padding: 10,
         flexGrow: 2,
       },
       header: {
         marginBottom: 20,
         textAlign: "center",
         fontSize: 16,
         fontWeight: "bold",
       },
       billTime: {
         marginBottom: 10,
       },
       companyInfo: {
         marginBottom: 20,
         fontSize: 12,
         flexDirection: "row",
       },
       recipientInfo: {
         marginLeft: "auto",
         fontSize: 12,
       },
       itemList: {
         marginBottom: 20,
         fontSize: 12,
       },
       tableHeader: {
         flexDirection: "row",
         borderTopWidth: 0.5,
         borderTopColor: "#000",
         alignItems: "center",
         padding: 10,
         fontWeight: "bold",
         color: data.fontColor,
       },

       tableRow: {
         backgroundColor:
           data.backgroundColor === "none" ? "#fff" : data.backgroundColor,
         flexDirection: "row",
         alignItems: "center",
         padding: 5,
         marginBottom: 5,
       },
       totalRow: {
         flexDirection: "row",
         justifyContent: "space-between", // Align items with space between
         borderTopWidth: 0.5,
         borderTopColor: "#000",
         padding: 10,
         width: "30%", // Adjust width as needed
         alignSelf: "flex-end", // Align the total row to the end of the section
       },
       totalRow2: {
         flexDirection: "row",
         justifyContent: "space-between", // Align items with space between
         padding: 10,
         width: "30%", // Adjust width as needed
         alignSelf: "flex-end", // Align the total row to the end of the section
       },
       stripedRow: {
         backgroundColor:
           data.backgroundColor === "none" ? "#f2f2f2" : data.backgroundColor, // or any other color you prefer
       },

       logo: {
         marginBottom: 10,
         width: 100, // Adjust width and height as needed
         height: 50,
       },
     };
     const css2: any = {
       page: {
         flexDirection: "row",
         backgroundColor: "#FFF", // Set background color to white for a clean look
         padding: 20,
       },
       section: {
         margin: 10,
         padding: 10,
         flexGrow: 2,
       },
       header: {
         marginBottom: 20,
         textAlign: "center",
         fontSize: 16,
         fontWeight: "bold",
       },
       billTime: {
         marginBottom: 10,
       },
       companyInfo: {
         marginBottom: 20,
         fontSize: 12,
         flexDirection: "row",
       },
       recipientInfo: {
         marginLeft: "auto",
         fontSize: 12,
       },
       itemList: {
         marginBottom: 20,
         fontSize: 12,
       },
       tableHeader: {
         flexDirection: "row",
         borderTopWidth: 0.5,
         borderTopColor: "#000",
         alignItems: "center",
         padding: 10,
         fontWeight: "bold",
         color: data.fontColor,
       },

       tableRow: {
         backgroundColor:
           data.backgroundColor === "none" ? "#fff" : data.backgroundColor,
         flexDirection: "row",
         alignItems: "center",
         padding: 5,
         // marginBottom: 5,
         borderWidth: 0.5,
         borderColor: "#000",
       },
       totalRow: {
         flexDirection: "row",
         justifyContent: "space-between", // Align items with space between
         // borderTopWidth: 0.5,
         // borderTopColor: "#000",
         padding: 10,
         width: "30%", // Adjust width as needed
         alignSelf: "flex-end", // Align the total row to the end of the section
         borderWidth: 0.5,
         borderColor: "#000",
         backgroundColor:
           data.backgroundColor === "none" ? "#fff" : data.backgroundColor,
       },
       totalRow2: {
         flexDirection: "row",
         justifyContent: "space-between", // Align items with space between
         padding: 10,
         width: "30%", // Adjust width as needed
         alignSelf: "flex-end", // Align the total row to the end of the section
         borderWidth: 0.5,
         borderColor: "#000",
       },

       logo: {
         marginBottom: 10,
         width: 100, // Adjust width and height as needed
         height: 50,
       },
     };
     const css1: any = {
       page: {
         flexDirection: "row",
         backgroundColor: "#FFF", // Set background color to white for a clean look
         padding: 20,
       },
       section: {
         margin: 10,
         padding: 10,
         flexGrow: 2,
       },
       header: {
         marginBottom: 20,
         textAlign: "center",
         fontSize: 16,
         fontWeight: "bold",
       },
       billTime: {
         marginBottom: 10,
       },
       companyInfo: {
         marginBottom: 20,
         fontSize: 12,
         flexDirection: "row",
       },
       recipientInfo: {
         marginLeft: "auto",
         fontSize: 12,
       },
       itemList: {
         marginBottom: 20,
         fontSize: 12,
       },
       tableHeader: {
         flexDirection: "row",
         borderTopWidth: 0.5,
         borderTopColor: "#000",
         alignItems: "center",
         padding: 10,
         fontWeight: "bold",
         color: data.fontColor,
       },

       tableRow: {
         backgroundColor:
           data.backgroundColor === "none" ? "#fff" : data.backgroundColor,
         flexDirection: "row",
         alignItems: "center",
         padding: 5,
         marginBottom: 5,
       },
       totalRow: {
         flexDirection: "row",
         justifyContent: "space-between", // Align items with space between
         borderTopWidth: 0.5,
         borderTopColor: "#000",
         padding: 10,
         width: "auto", // Adjust width as needed
         alignSelf: "flex-end", // Align the total row to the end of the section
       },
       totalRow2: {
         flexDirection: "row",
         justifyContent: "space-between", // Align items with space between
         padding: 10,
         width: "auto", // Adjust width as needed
         alignSelf: "flex-end", // Align the total row to the end of the section
       },

       logo: {
         marginBottom: 10,
         width: 75, // Adjust width and height as needed
         height: 35,
       },
     };
  switch (data.layoutFormat) {
    case "light":
     return {
        ...css1,
      };
    case "boxed":
      return {
        ...css2,
      };
    case "striped":
      return {
        ...css3,
      };
    case "bold":
      return {
        ...css4,
      };
    default:
      return css1;
  }
};

export default selectStyles;
