//@ts-nocheck
import axios from "axios";
import { createRefresh } from "react-auth-kit";
import common from "./config.json";
import jwt_decode from "jwt-decode";

const MAX_RETRY_ATTEMPTS = 5;
const RETRY_DELAY_MS = 1000; // 1 second

const refreshTokenApi = createRefresh({
  interval: 4,
  refreshApiCallback: async ({refreshToken,authUserState} ) => {
    return new Promise(async (resolve, reject) => {
      let baseUrl = common.BASE_URL+ common.USER_API_URL + "/refreshToken";

      let retryCount = 0;

      const performRefresh = async () => {
        try {
          const response = await axios.post(baseUrl,  {refreshToken:refreshToken});

          const decoded = jwt_decode(response.data.accessToken); 

          resolve({
            isSuccess: true,
            newAuthToken: response.data.accessToken,
            newAuthTokenExpireIn: response.data.expiresIn,
            newRefreshToken: response.data.refreshToken,
            newAuthUserState: {
              token: response.data.accessToken,
              id: decoded.userId,
              userName: decoded.userName,
            },
          });
        } catch (error) {
          console.log(error.response);
          if (retryCount < MAX_RETRY_ATTEMPTS) {
            retryCount++;
            console.log(`Retrying refresh (attempt ${retryCount})...`);
            setTimeout(performRefresh, RETRY_DELAY_MS);
          } else {
            console.log("Max retry attempts reached. Refresh failed.");
            reject({
              isSuccess: false,
            });
          }
        }
      };

      performRefresh();
    });
  },
});

export default refreshTokenApi;

