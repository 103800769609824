
import axios from "axios";
import { Dispatch } from "redux";
import common from "../../config.json";
import {
  INVOICE_DATA
} from "./actionTypes";

export type AuthAction = {
  type: string;
  payload?: any;
};

export const getInvoiceData = (invoiceNumber: any, token: any) => {
  return async (dispatch: any) => {
    const url = common.BASE_URL + common.INVOICE_API;
    try {
      const response = await axios.get(`${url}/${invoiceNumber}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const responseData = response.data.data;
      let details = {
        items: responseData.items.map((item: any) => ({
          product: item.product_id,
          pname: item.p_name, // You can set this to an empty string or another value if needed
          hsn: item.hsn,
          quantity: item.quantity,
          price: item.unit_price,
          tax: item.tax_rate,
          amount: item.amount,
        })),
        companyId: "",
        customerId: responseData.customer_id,
        customerName: responseData.customerName,
        createdDate: responseData.created_date,
        dueDate: responseData.due_date,
        paymentType: responseData.payment_type,
        invoiceNumber: responseData.invoice_number,
        totalAmount: responseData.total_amount,
        status: responseData.status,
        sgst: responseData.sgst_amount,
        cgst: responseData.cgst_amount,
        template_no: responseData.template_no,
        taxedTotalAmount: "",
        css: {},
        companyDetails: {},
        customerDetails: {},
      };
      dispatch({ type: INVOICE_DATA, payload: details });
    } catch (error) {
      console.error("Login error:", error);
    }
  };
};
