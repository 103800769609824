import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

// Third-party libraries
import { Formik } from "formik";
import * as Yup from "yup";
import { Add, DeleteOutline } from "@mui/icons-material";
import SnackBar from "../../components/Snackbar";
import MyDocument from "../Configurations/LayoutSetUp/Template1";
import { PDFViewer, StyleSheet } from "@react-pdf/renderer";
import selectStyles from "../Configurations/LayoutSetUp/StyleFunction";
import { formatDate, formatInput, generateInvoiceNumber } from "../ValidationPage";
import axios from "axios";
import common from "../../config.json";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { AuthState } from "../../store/rootReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CHANGE_INDEX } from "../../store/sideBarIndex/actionTypes";


const CreatedInvoice = () => {
  const authUser: any = useAuthUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = authUser()?.token;
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const [customers, setCustomer] = useState([]);
  const [products, setProducts] = useState([]);
   const [companyType, setCompanyType] = useState("others");
  const invoiceDetails: any = useSelector(
    (state: AuthState) => state.invoicePage.invoiceData
  );
  const [invoiceData, setInvoiceData] = useState({
    items: [
      {
        product: "",
        pname: "",
        hsn: "",
        quantity: "",
        price: "",
        tax: "",
        amount: "",
      },
    ],
    companyId: "",
    customerName:"",
    customerId: "",
    createdDate: "",
    dueDate: "",
    paymentType: "",
    invoiceNumber: "",
    totalAmount: "",
    status: "Draft",
    sgst: "",
    cgst: "",
    template_no: "",
    taxedTotalAmount: "",
    css: {},
    companyDetails: {},
    customerDetails: {},
  });

  useEffect(() => {
     getCompanyData();
    if (!invoiceDetails.invoiceNumber) {
      fetchInvoiceNumber();
      setInvoiceData({
        items: [
          {
            product: "",
            pname: "",
            hsn: "",
            quantity: "",
            price: "",
            tax: "",
            amount: "",
          },
        ],
        companyId: "",
        customerName:"",
        customerId: "",
        createdDate: "",
        dueDate: "",
        paymentType: "",
        invoiceNumber: "",
        totalAmount: "",
        status: "Draft",
        sgst: "",
        cgst: "",
        template_no: "",
        taxedTotalAmount: "",
        css: {},
        companyDetails: {},
        customerDetails: {},
      });
    } else {
      setInvoiceData({
        items: invoiceDetails.items || [],
        companyId: invoiceDetails.companyId || "",
        customerId: invoiceDetails.customerId || "",
        customerName:invoiceDetails.customerName || "",
        createdDate: formatDate(invoiceDetails.createdDate) || "",
        dueDate: formatDate(invoiceDetails.dueDate) || "",
        paymentType: invoiceDetails.paymentType || "",
        invoiceNumber: invoiceDetails.invoiceNumber || "",
        totalAmount: invoiceDetails.totalAmount || "",
        status: invoiceDetails.status || "",
        sgst: invoiceDetails.sgst || "",
        cgst: invoiceDetails.cgst || "",
        template_no: invoiceDetails.template_no || "",
        taxedTotalAmount: invoiceDetails.taxedTotalAmount || "",
        css: invoiceDetails.css || {},
        companyDetails: invoiceDetails.companyDetails || {},
        customerDetails: invoiceDetails.customerDetails || {},
      });
    }

    // Fetch other data
    getCustomerList();
    getAllProduct();
    getTemplateData();
  }, [invoiceDetails.invoiceNumber]); 

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen(false);
  };

  const fetchInvoiceNumber = async () => {
    try {
      const invoiceNumber = await generateInvoiceNumber("INV");
      console.log("Generated Invoice Number:", invoiceNumber);
      setInvoiceData((prevValue: any) => ({
        ...prevValue,
        invoiceNumber: invoiceNumber,
      }));
    } catch (error) {
      // Handle error
      console.error("Error setting invoice number:", error);
    }
  };

  const getTemplateData = async () => {
    let baseUrl = common.BASE_URL + common.TEMPLATE_API;

    try {
      const response = await axios.get(baseUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.success === true) {
        const singleTemplate = response.data.data;
        const apiResponseData = {
          layoutFormat: singleTemplate.layout_type,
          backgroundColor: singleTemplate.bg_color,
          fontColor: singleTemplate.font_color,
          templateType: singleTemplate.template_number,
        };
        const selectedStyles = await selectStyles(apiResponseData);
        const styles = StyleSheet.create(selectedStyles);
        setInvoiceData((prevData: any) => ({
          ...prevData,
          template_no: apiResponseData.templateType,
          css: {
            ...styles,
          },
        }));
      }
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message || error.statusText);
    }
  };

  const createInvoice = async (invoiceData: any) => {
    let baseUrl = common.BASE_URL + common.INVOICE_API + "/create";
    try {
      const response = await axios
        .post(baseUrl, invoiceData, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          if (response.data) {
            setOpen(true);
            setType("success");
            setMessage("You have successfully new invoice added.");
          }
        });
      console.log(response);
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message || error.response.statusText);
    }
  };
    const updateInvoice = async (invoiceData: any) => {
      let baseUrl = `${common.BASE_URL}${common.INVOICE_API}/edit/${invoiceDetails.invoiceNumber}`;
      try {
        const response = await axios
          .put(baseUrl, invoiceData, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then(function (response) {
            if (response.data) {
              setOpen(true);
              setType("success");
              setMessage("You have successfully updated invoice.");
            }
          });
        console.log(response);
      } catch (error: any) {
        setOpen(true);
        setType("error");
        setMessage(error.response.data.message || error.response.statusText);
      }
    };

  const confirmInvoice = async () => {
    let baseUrl = common.BASE_URL + common.INVOICE_API + "/confirm";
    const data = {
      invoiceNumber: invoiceData.invoiceNumber,
      status: "Pending",
    };
    try {
      const response = await axios
        .put(baseUrl, data, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          if (response.data) {
            setOpen(true);
            setType("success");
            setMessage("You have successfully created invoice.");
             dispatch({
               type: CHANGE_INDEX,
               value: 4,
             });
            dispatch({ type: "RESET_STATE" });
            navigate("/invoices");
          }
        });
      console.log(response);
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message || error.response.statusText);
    }
  };

  const getCustomerList = async () => {
    let baseUrl = common.BASE_URL + common.CUSTOMERs_API + "/list";

    try {
      const response = await axios.get(
        baseUrl,

        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const transformedData: any = response.data.data.map((item: any) => ({
        customerId: item.id,
        customerName: `${item.customer_name}(${item.customer_code} )`,
      }));
      setCustomer(transformedData);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCustomerData = async (customerId: any) => {
    try {
      let baseUrl = common.BASE_URL + common.CUSTOMERs_API + "/list";
      const response = await axios.get(baseUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const customer = response.data.data.find(
        (customer: any) => customer.id === customerId
      );
      if (customer) {
        const billingDetails: any = {
          name: customer.customer_name,
          address: `${customer.s_address}, ${customer.s_city}, ${customer.s_state}`,
          country: `${customer.s_country},- ${customer.s_postalcode}`,
          gstNo: customer.gst_number,
        };
        return billingDetails;
      } else {
        console.log("Customer not found");
        return null;
      }
    } catch (error) {
      console.log("Error fetching customer data:", error);
      return null;
    }
  };

  const getCompanyData = async () => {
    let baseUrl = common.BASE_URL + common.COMPANY_DATA_API;

    try {
      const response = await axios.get(baseUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.success === true) {
        const companyData = response.data.data;

        const companyDetails: any = {
          id: companyData.id,
          logo: companyData.logo_image,
          name: companyData.name,
          address: `${companyData.address} ,${companyData.city},${companyData.state}`,
          country: `${companyData.country}-${companyData.postalcode}`,
          gstNo: companyData.gst_no,
          contact: `${companyData.phone} | ${companyData.email}`,
        };
        setCompanyType(companyData.companyType);
        setInvoiceData((prevValue: any) => ({
          ...prevValue,
          companyId: companyData.id,
        }));

        return companyDetails;
      } else {
        return null;
      }
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message || error.statusText);
    }
  };

  const getAllProduct = async () => {
    let baseUrl = common.BASE_URL + common.PRODUCT_API + "/products";

    try {
      const response = await axios.get(
        baseUrl,

        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      console.log("product list", response);

      const transformedData: any = response.data.data.map((item: any) => ({
        pname: item.item_name,
        value: item.item_code,
        label: `${item.item_name} (${item.item_code})`,
        price: item.selling_price.toFixed(2),
        hsn: item.hsn_code,
        tax: item.tax_rate || 18,
      }));
      setProducts(transformedData);
    } catch (error) {
      console.log("error", error);
    }
  };

  async function fetchProductDetails(productCode: any) {
    try {
      // Find the product details in the hardcoded products array
      const product = products.find(
        (product: any) => product.value === productCode
      );

      // If the product is not found, throw an error
      if (!product) {
        throw new Error(`Product with ID ${productCode} not found`);
      }

      // Return the product details
      return product;
    } catch (error) {
      // Handle any errors
      console.error("Error fetching product details:", error);
      throw error;
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={invoiceData}
      validationSchema={Yup.object().shape({
        [companyType === "others" ? "customerId" : "customerName"]:
          Yup.string().required("Customer name is required"),
        createdDate: Yup.date().required("Created date is required"),
        dueDate: Yup.date().required("Due date is required"),
        paymentType: Yup.string().required("Payment type is required"),
        items: Yup.array().of(
          Yup.object().shape({
            product: Yup.string().required("Product is required"),
            hsn: Yup.string().required("HSN/SAC is required"),
            quantity: Yup.number()
              .typeError("Quantity must be a number")
              .positive("Quantity must be a positive number")
              .required("Quantity is required"),
            price: Yup.number()
              .typeError("Price must be a number")
              .positive("Price must be a positive number")
              .required("Price is required"),
            tax: Yup.number()
              .typeError("Tax must be a number")
              .positive("Tax must be a positive number")
              .required("Tax is required"),
            amount: Yup.number().required("Amount is required"),
          })
        ),
      })}
      onSubmit={async (values, action) => {
        let totalAmount = 0;
        values.items.forEach((item: any) => {
          totalAmount += parseFloat(item.amount);
        });
        const taxPercentage = 18;
        const totalTaxAmount = (taxPercentage / 100) * totalAmount;
        const taxedTotalAmount: any = totalAmount + totalTaxAmount;
        const sgst = totalTaxAmount / 2;
        const cgst = totalTaxAmount / 2;

        const billingDetails = await getCustomerData(values.customerId);
        const companyDetails = await getCompanyData();
        const data = {
          ...values,
          css: invoiceData.css,
          companyId: companyDetails.id,
          template_no: invoiceData.template_no,
          invoiceNumber: invoiceData.invoiceNumber,
          companyDetails: companyDetails,
          billingDetails: billingDetails,
          totalAmount: totalAmount.toFixed(2), // Round to 2 decimal places
          taxedTotalAmount: taxedTotalAmount.toFixed(2), // Round to 2 decimal place
          sgst: sgst.toFixed(2),
          cgst: cgst.toFixed(2),
        };
        setInvoiceData(data);
        if (invoiceDetails.invoiceNumber) {
          await updateInvoice(data);
        } else {
          await createInvoice(data);
        }
        console.log("INVOICE DATA", invoiceData);
        setShow(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm,
      }: any) =>
        show ? (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Container maxWidth={false} disableGutters={false}>
              <Grid container spacing={0}>
                <Paper
                  elevation={0}
                  className="page-container-paper"
                  sx={{
                    borderRadius: "12px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        fullWidth
                        id="standard-basic"
                        label="Invoice No."
                        variant="standard"
                        name="invoiceNumber"
                        value={invoiceData.invoiceNumber}
                      />
                    </Grid>
                    {companyType === "others" ? (
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          fullWidth
                          required
                          id="standard-select-customer"
                          select
                          label="Customer Name"
                          defaultValue=""
                          value={values.customerId}
                          name="customerId"
                          variant="standard"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {customers.map((option: any, index: number) => (
                            <MenuItem key={index} value={option.customerId}>
                              {option.customerName}
                            </MenuItem>
                          ))}
                        </TextField>
                        {touched.customerId && errors.customerId && (
                          <FormHelperText
                            error
                            id="standard-select-customer-helper-text"
                          >
                            {errors.customerId}
                          </FormHelperText>
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          required
                          id="customerName"
                          label="Customer Name"
                          fullWidth
                          size="small"
                          variant="standard"
                          value={formatInput(values.customerName)}
                          name="customerName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.customerName && errors.customerName
                          )}
                        />
                        {touched.customerName && errors.customerName && (
                          <FormHelperText error id="customerName-helper-text">
                            {errors.customerName}
                          </FormHelperText>
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        variant="standard"
                        select
                        id="paymentType"
                        name="paymentType"
                        label="Payment mode"
                        fullWidth
                        value={values.paymentType}
                        onChange={handleChange}
                      >
                        <MenuItem value="cash">Cash</MenuItem>
                        <MenuItem value="online">Online</MenuItem>
                      </TextField>
                      {touched.paymentType && errors.paymentType && (
                        <FormHelperText error>
                          {errors.paymentType}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="createdDate"
                        label="Created Date"
                        type="date"
                        fullWidth
                        variant="standard"
                        size="small"
                        value={values.createdDate}
                        name="createdDate"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.createdDate && errors.createdDate
                        )}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {touched.createdDate && errors.createdDate && (
                        <FormHelperText error id="createdDate-helper-text">
                          {errors.createdDate}
                        </FormHelperText>
                      )}
                    </Grid>
                    {/* Due Date */}
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="dueDate"
                        label="Due Date"
                        type="date"
                        fullWidth
                        size="small"
                        variant="standard"
                        value={values.dueDate}
                        name="dueDate"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.dueDate && errors.dueDate)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {touched.dueDate && errors.dueDate && (
                        <FormHelperText error id="dueDate-helper-text">
                          {errors.dueDate}
                        </FormHelperText>
                      )}
                    </Grid>
                    {/* Payment Type */}

                    {/* Table for Product Input */}
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table aria-label="product table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Product</TableCell>
                              <TableCell>HSN/SAC</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Unit Price</TableCell>
                              <TableCell>Taxes (%)</TableCell>
                              <TableCell align="right">
                                <Button
                                  className="custom-btn"
                                  startIcon={<Add />}
                                  onClick={() =>
                                    setFieldValue("items", [
                                      ...values.items,
                                      {
                                        product: "",
                                        pname: "",
                                        quantity: "",
                                        hsn: "",
                                        price: "",
                                        tax: "",
                                        amount: "",
                                      },
                                    ])
                                  }
                                  variant="contained"
                                  color="primary"
                                >
                                  Add
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.items.map((row: any, index: number) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <FormControl
                                    fullWidth
                                    required
                                    size="small"
                                    variant="outlined"
                                    error={Boolean(
                                      touched.items &&
                                        touched.items[index] &&
                                        touched.items[index].product &&
                                        errors.items &&
                                        errors.items[index] &&
                                        errors.items[index].product
                                    )}
                                  >
                                    <InputLabel id={`product-label-${index}`}>
                                      Product
                                    </InputLabel>
                                    <Select
                                      labelId={`product-label-${index}`}
                                      id={`product-select-${index}`}
                                      value={values.items[index].product}
                                      onChange={(event) => {
                                        const productId = event.target.value;
                                        // Fetch product details based on productId
                                        fetchProductDetails(productId)
                                          .then((productDetails: any) => {
                                            // Update the item's price in values.items
                                            const updatedItems = [
                                              ...values.items,
                                            ];
                                            updatedItems[index].product =
                                              productDetails.value;
                                            updatedItems[index].pname =
                                              productDetails.label;
                                            updatedItems[index].hsn =
                                              productDetails.hsn;
                                            updatedItems[index].tax =
                                              productDetails.tax;
                                            updatedItems[index].price =
                                              productDetails.price;
                                            updatedItems[index].quantity = null;
                                            updatedItems[index].amount = null;
                                            setFieldValue(
                                              `items`,
                                              updatedItems
                                            );
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error fetching product details:",
                                              error
                                            );
                                          });
                                      }}
                                      onBlur={handleBlur}
                                      label="Product"
                                      style={{ width: "200px" }}
                                    >
                                      {products.map(
                                        (option: any, index: number) => (
                                          <MenuItem
                                            key={index}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                    {touched.items &&
                                      touched.items[index] &&
                                      touched.items[index].product &&
                                      errors.items &&
                                      errors.items[index] &&
                                      errors.items[index].product && (
                                        <FormHelperText
                                          id={`product-helper-text-${index}`}
                                          error
                                        >
                                          {errors.items[index].product}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name={`items[${index}].hsn`}
                                    value={values.items[index].hsn}
                                    onChange={handleChange}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name={`items[${index}].quantity`}
                                    value={values.items[index].quantity}
                                    onChange={(event) => {
                                      const newQuantity = event.target.value;
                                      const newAmount =
                                        parseFloat(newQuantity) *
                                        parseFloat(values.items[index].price);
                                      const updatedRows = [...values.items];
                                      updatedRows[index].quantity = newQuantity;
                                      updatedRows[index].amount =
                                        newAmount.toFixed(2); // Format to 2 decimal places
                                      setFieldValue(
                                        `items[${index}].quantity`,
                                        newQuantity
                                      );
                                      setFieldValue(
                                        `items[${index}].amount`,
                                        newAmount.toFixed(2)
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name={`items[${index}].price`}
                                    value={values.items[index].price}
                                    onChange={handleChange}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name={`items[${index}].tax`}
                                    value={values.items[index].tax}
                                    onChange={handleChange}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    name={`items[${index}].amount`}
                                    value={values.items[index].amount}
                                    onChange={handleChange}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Button
                                    onClick={() => {
                                      const updatedRows = [...values.items];
                                      updatedRows.splice(index, 1);
                                      setFieldValue("items", updatedRows);
                                    }}
                                    variant="outlined"
                                    color="error"
                                  >
                                    <DeleteOutline />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    {/* Submit Button */}
                    <Grid item xs={12}>
                      <Button
                        className="custom-btn"
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mr: "16px" }}
                      >
                        Review
                      </Button>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Container>
          </form>
        ) : (
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              className="page-container-paper"
              sx={{ borderRadius: "8px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PDFViewer width="100%" height="500" showToolbar={false}>
                    <MyDocument
                      invoiceData={invoiceData}
                      styles={invoiceData.css}
                    />
                  </PDFViewer>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="custom-btn"
                    variant="contained"
                    size="large"
                    sx={{ mr: "16px" }}
                    onClick={confirmInvoice}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => setShow(true)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
              {open ? (
                <SnackBar onClose={handleClose} data2={type} data3={message} />
              ) : (
                ""
              )}
            </Paper>
          </Grid>
        )
      }
    </Formik>
  );
};

export default CreatedInvoice;
