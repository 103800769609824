import { useContext } from "react";

// material ui
import {
  Button,
  Container,
  Divider,
  Grid,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";

// project import
import { ProductContext } from "./ProductPage";
// import image4 from "../../../images/Avatar4.png";
import "../../Styles/PagesStyle/index.css";
import { replaceUnderscoresWithSpaces } from "../ValidationPage";
 import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const ProductView = () => {
  const { setPage, setTest, user } = useContext(ProductContext);
  const {
    //image,
    productCode,
    name,
    price,
    brand,
    description,
    hsnCode,
    status,
    category,
   
  } = user;

  return (
    <>
      <Container maxWidth={false} disableGutters={false}>
        <Grid container spacing={0} maxWidth="850px">
          <Paper
            elevation={0}
            className="page-container-paper"
            sx={{
              borderRadius: "16px",
            }}
          >
            <Typography variant="h5" sx={{ pb: "24px" }}>
              Product Details
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">{productCode}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <InputLabel>Name:&nbsp; </InputLabel>
                    <Typography variant="body1">{name}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={6}>
                <img width="120px" height="120px" src={image4} alt="" />
              </Grid> */}

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <InputLabel>Price: &nbsp; </InputLabel>
                <Typography variant="body1">{price}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <InputLabel>Brand: &nbsp; </InputLabel>
                <Typography variant="body1">{brand}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <InputLabel>Category: &nbsp; </InputLabel>
                <Typography variant="body1">{category}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <InputLabel>HSN Code: &nbsp; </InputLabel>
                <Typography variant="body1">{hsnCode}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <InputLabel>Description: &nbsp; </InputLabel>
                <Typography variant="body1">{description}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Button
                  className="custom-btn2"
                  variant="outlined"
                  onClick={() => {
                    setPage("");
                    setTest(0);
                  }}
                >
                  <KeyboardBackspaceIcon />
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </>
  );
};

export default ProductView;
