import { useState } from "react";
import "../../Styles/UserformStyle.css";
import { APP_LOGO } from "../../constant";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Container,
  Typography,
  Button,
  Box,
  FormHelperText,
  TextField,
  FormControl,
} from "@mui/material";

import axios from "axios";
import common from "../../config.json";
import AlertBar from "../../components/Alert";


import { useNavigate} from "react-router-dom";
import SnackBar from "../../components/Snackbar";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email address cannot be blank"),
});

interface IFormInput {
  email: string;
  placeValue:string;
}

const ForgetPassword = () => {
  const navigate = useNavigate();
  const[error ,setError] = useState(null);
  const[active,setActive] = useState(false);
    const [message, setMessage] = useState("");
   


  
  

  return (
    <Formik
      initialValues={{
        email: "",
        placeValue: "yours@example.com",
      }}
      validationSchema={validationSchema}
      onSubmit={async (data: IFormInput) => {
        let baseUrl = `${common.BASE_URL}${common.USER_API_URL}/resetpassword/${data.email}`;
        try {
          const response = await axios.get(baseUrl, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          console.log("vtf", response);
          if (response.data.success) {
            navigate("/confirmOtp", {
              state: {
                email: data.email,
              },
            });
          }
          if (response.data.success === false) {
            setError(response.data.message);
          }
        } catch (error: any) {
          setError(error.response.data.message);
        }
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        values,
        touched,
        handleBlur,
      }) => (
        <Container
          className="bg-image"
          maxWidth={false}
          sx={{ height: "100vh" }}
        >
          <Box className="resetCode-form">
            <div>
              <img className="logo-image" src={APP_LOGO} alt="logo"></img>
            </div>

            <div className="top-text1">
              <Typography variant="h5" sx={{ pb: "16px" }}>
                Reset Password
              </Typography>

              <Typography variant="subtitle1" color="rgba(0, 0, 0, 0.6)">
                Please enter your registered email address. We will send you an
                OTP to reset your password.
              </Typography>
            </div>

            <form className="form-input-box" onSubmit={handleSubmit}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-text"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  placeholder="Email"
                  value={active ? values.email : values.placeValue}
                  name="email"
                  onClick={() => setActive(true)}
                  onChange={handleChange}
                  sx={{ mt: "16px" }}
                  error={Boolean(touched.email && errors.email)}
                />
                {errors.email ? (
                  <FormHelperText error id="outlined-text">
                    {errors.email}
                  </FormHelperText>
                ) : (
                  <FormHelperText error id="outlined-text">
                    {message}
                  </FormHelperText>
                )}
              </FormControl>
              {error != null ? <AlertBar message={error} /> : ""}
              <Button
                className="custom-btn"
                variant="contained"
                sx={{
                  fontSize: "15px",
                  width: "100%",
                  height: "42px",
                  marginTop: "16px",
                }}
                type="submit"
              >
                Continue
              </Button>
              {/* <Grid item xs={12}>
                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    pt: "12px",
                  }}
                  onClick={HandleByPhone}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      borderBottom: "1px solid #1565C0",
                    }}
                  >
                    Switch to mobile authentication
                  </Typography>
                </Button>
              </Grid> */}
            </form>
          </Box>
        </Container>
      )}
    </Formik>
  );
};

export default ForgetPassword;
