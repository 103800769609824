import "./App.css";
import UserRegistrationForm from "./Pages/SignUpPages/SignUpForm";
import LoginPage from "./LoginPage/LoginPage";
import CustomersPage from "./Pages/Customers/CustomerPage";
import ProductPage from "./Pages/Products/ProductPage";
import VendorsPage from './Pages/Vendors/VendorPage';
import CompanyDataForm from "./Pages/Configurations/CompanyDetails/CompanyDataForm";
import LayoutConfigPage from "./Pages/Configurations/LayoutSetUp/LayoutPage";
import InvoicesPage from "./Pages/Invoices";

import { Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Captcha from "./Pages/SignUpPages/Captcha";
import ConfirmSignup from "./Pages/SignUpPages/ConfirmSignup";
import ForgetPassword from "./Pages/ForgetPasswordPages/ForgetPassword";
import DashBoardPage from "./Pages/DashBoardPage";
import NewPasswordForm from "./Pages/ForgetPasswordPages/NewPassword";
import ResetOtpform from "./Pages/ForgetPasswordPages/ConfirmOtp";
import MyAccountPage from "./Pages/admin/MyAccountDetails";
import ErrorPage from "./Pages/ErrorPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditionsPage from "./Pages/TermsAndConditionsPage";
import InvoicesList from "./Pages/ListInvoice";
import { RequireAuth } from "react-auth-kit";



const App = () => {
  return (
    <>
      <div className="App">
        <Routes>
          {/* <Route
            path="*"
            element={
              <ErrorPage
                message={"We can’t seem to find the page you’re looking for."}
                status={404}
              />
            }
          /> */}
          <Route path="/" element={<LoginPage />} />
          <Route path="register" element={<UserRegistrationForm />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="forgetpassword" element={<ForgetPassword />} />
          <Route path="confirmOtp" element={<ResetOtpform />} />
          <Route path="newPassword" element={<NewPasswordForm />} />
          <Route path="captcha" element={<Captcha />} />
          <Route path="confirmEmail" element={<ConfirmSignup />} />
          <Route path="privacy_policy" element={<PrivacyPolicy />} />
          <Route
            path="terms_and_conditions"
            element={<TermsAndConditionsPage />}
          />
          <Route
            path="/*"
            element={
             <RequireAuth loginPath="/login">
              <Dashboard />
             </RequireAuth>
            }
          >
            <Route path="homepage" element={<DashBoardPage />} />
            <Route path="customers" element={<CustomersPage />} />
            <Route path="vendors" element={<VendorsPage />} />
            <Route path="products" element={<ProductPage />} />
            <Route path="companyDetails" element={<CompanyDataForm />} />
            <Route path="invoice" element={<InvoicesPage />} />
            <Route path="invoices" element={<InvoicesList />} />
            <Route path="layoutConfig" element={<LayoutConfigPage />} />
            {/* <Route path="nurseHomePage" element={<NewNurseView />} />
            <Route path="medicalOfficerHomePage" element={<NewNurseView />} />
            <Route path="adminHomePage" element={<AdminHome />} />
            <Route path="mainHomePage" element={<MainHomePage />} /> */}

            {/* <Route path="adminSetting" element={<AdminSettingsPage />}>
              <Route path="add_staff" element={<AddStaff />} />
            </Route>
            <Route path="rooms" element={<RoomBedManagement />}>
              <Route path="add_room" element={<RoomBedManagement />} />
            </Route>
            <Route path="addons" element={<AddonsPage />} /> */}
            <Route path="myAccount" element={<MyAccountPage />} />
            {/* <Route path="search" element={<SearchPage />} /> */}
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default App;
