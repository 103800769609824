import { Box, CssBaseline } from "@mui/material";
import { Outlet, useNavigate} from "react-router-dom";
import NavbarDrawer from "./NavbarDrawer";
import { useAuthUser, useSignIn } from "react-auth-kit";
import { useEffect, useState } from "react";
import LoadingComponent from "../components/LoadingComponent";



const Dashboard = () => {
  const authUser: any = useAuthUser();
  const userId = authUser()?.id;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (userId) {
        setLoading(false);
      }
    }, 2000);
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>   
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <NavbarDrawer />
          <Box className="mainContainer" component="main">               
            <Outlet />
          </Box>
        </Box> 
    </>
  );
};

export default Dashboard;
