import { INVOICE_DATA, RESET_STATE } from "./actionTypes";
import { stepperActions } from "./types";

// Define initial state with the entire invoice data object
const initialState = {
  invoiceData: {
    items: [
      {
        product: "",
        pname: "",
        hsn: "",
        quantity: "",
        price: "",
        tax: "",
        amount: "",
      },
    ],
    companyId: "",
    customerName:"",
    customerId: "",
    createdDate: "",
    dueDate: "",
    paymentType: "",
    invoiceNumber: "",
    totalAmount: "",
    status: "",
    sgst: "",
    cgst: "",
    template_no: "",
    taxedTotalAmount: "",
    css: {},
    companyDetails: {},
    customerDetails: {},
  },
};

const sideBarReducers = (state = initialState, action: stepperActions) => {
  switch (action.type) {
    case INVOICE_DATA:
      return {
        ...state,
        invoiceData: action.payload,
      };
    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

export default sideBarReducers;
