import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { DefaultizedPieValueType } from "@mui/x-charts";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";

const sizing = {
  margin: { right: 5 },
  width: 300,
  height: 300,
  legend: { hidden: true },
};



export default function PieChartWithCustomizedLabel({data}:any) {
  console.log(">>>",data)



const total = data
  .map((item: any) => (typeof item.value === "number" ? item.value : 0)) // Parse value to number, default to 0 if not a valid number
  .reduce((a: number, b: number) => a + b, 0);

  const getArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / total;
    return `${(percent * 100).toFixed(0)}%`;
  };

  return (
    <Grid item xs={12}>
      <Paper elevation={1} sx={{ p: "16px", borderRadius: "12px" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          alignItems={"center"}
          minHeight="300px"
        >
          <PieChart
            series={[
              {
                outerRadius: 150,
                data,
                arcLabel: getArcLabel,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontSize: 14,
              },
            }}
            {...sizing}
          />
        </Box>
      </Paper>
    </Grid>
  );
}
