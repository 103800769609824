import { useEffect, useState } from "react";
import "../Styles/PagesStyle/index.css";

// material-ui
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

//import axios from "axios";
import common from "../config.json";
import SnackBar from "../components/Snackbar";
import { useAuthUser } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../store/rootReducer";
import axios from "axios";
import { getCustomerNames } from "./Fuctions";
import PopupModal from "./PopModel";
import { useNavigate } from "react-router-dom";
import { getInvoiceData } from "../store/inoiceEdit/action";
import { CHANGE_INDEX } from "../store/sideBarIndex/actionTypes";
import ShareModel from "./ShareModel";

export interface Data {
  id: number;
  invoiceCode: string;
  customerName: string;
  createdDate: string;
  dueDate: string;
  amount: number;
  status: string;
  customerId:string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "invoiceCode",
    numeric: false,
    disablePadding: false,
    label: "Invoice Code",
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "Customer name",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "dueDate",
    numeric: true,
    disablePadding: false,
    label: "Due Date",
  },

  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Share",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox" sx={{ paddingLeft: "4px !important" }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            // onChange={}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            disabled={true}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant="body1">{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={{ display: "none" }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))} 
      </TableRow>
    </TableHead>
  );
}

const DashBoardTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser: any = useAuthUser();
   const selectedIndex: any = useSelector(
     (state: AuthState) => state.sideBarReducer
   );
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const[modalOpen,setModalOpen]= useState(false);
   const [modalOpen1, setModalOpen1] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [text, setText] = useState("");
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("createdDate");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [rows, setRows] = useState<Data[]>([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const token = authUser()?.token;

  useEffect(() => {
    getAllInvoices();
    console.log(rowsPerPage, pageNumber);
  }, [rowsPerPage, pageNumber, refresh]);

  const getAllInvoices = async () => {
    let baseUrl = common.BASE_URL + common.INVOICE_API + "/list";
    
    try {
      const response = await axios.get(
        baseUrl,

        {
          params: {
            pageNumber: pageNumber,
            recordsPerPage: rowsPerPage,
          },
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      
     const transformedData = await Promise.all(
       response.data.data.map(async (item:any) => {
      const customerName =
        item.customerName || (await getCustomerNames(item.customer_id, token));
         return {
           id: item.id,
           invoiceCode: item.invoice_number,
           customerId: item.customer_id,
           customerName: customerName,
           createdDate: new Date(item.created_date).toLocaleDateString(),
           dueDate: new Date(item.due_date).toLocaleDateString(),
           amount: item.total_amount,
           status: item.status.charAt(0).toUpperCase() + item.status.slice(1),
         };
       })
     );
      setRows(transformedData);
    
    } catch (error:any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message || error.statusText);
    }
  };


    const updateStatus = (row:any) => {
      let baseUrl = common.BASE_URL+common.INVOICE_API+"/status";
      let data = { invoiceNumber: row.invoiceCode, status: row.status };
       const isConfirmed = window.confirm(
         "Are you sure you want to update the status of this invoice?"
       );
      if (isConfirmed) {
        axios
          .put(baseUrl, data, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            setOpen(true);
            setType("success");
            setMessage("You have successfully updated invoice stage.");
            setRefresh(!refresh);
          })
          .catch((error:any) => {
           setOpen(true);
           setType("error");
           setMessage(error.response.data.message || error.response.statusText);
          });
      }
    };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen(false);
  };

  const handleModalOpen = async(data:any) => {
   await setInvoiceData(data);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
   const handleModalOpen1 = async (data: any) => {
    await setInvoiceData(data);
     setModalOpen1(true);
   };

   const handleModalClose1 = () => {
     setModalOpen1(false);
   };

  const handleChangePage = (event: unknown, newPage: number) => {
    console.log("page",newPage);
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("row",event.target.value);
    setRowsPerPage(parseInt(event.target.value));
    
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.invoiceCode);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleDownload=(data:any)=>{
    console.log("download");
    download(data.invoiceCode);
  }

  const download = async (number: any) => {
    try {
      const baseUrl = `${common.BASE_URL}${common.INVOICE_API}/download/${number}`;
      const response = await axios.get(
        baseUrl,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const pdfUrl = response.data.data.invoice_pdf;
      console.log(pdfUrl);
      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.download = `${number}.pdf`;
      downloadLink.click();
      setOpen(true);
      setType("success");
      setMessage("PDF file downloaded successfully.");
      setRefresh(!refresh);
    } catch (error:any) {
      setOpen(true);
      setType("error");
      setMessage(
        error.response?.data?.message ||
          error.message ||
          "An error occurred while downloading the PDF file."
      );
    }
  };
  // const handleSendMail = async (number: any) => {
  //   try {
  //     const baseUrl = `${common.BASE_URL}${common.INVOICE_API}/mailInvoice/${number}`;
  //     const response = await axios.get(baseUrl, {
  //       headers: {
  //         Authorization: "Bearer " + token,
  //       },
  //     });
  //     if(response.data.success){
  //        setOpen(true);
  //        setType("success");
  //        setMessage("Mail Send successfully.");
  //     };
     
  //   } catch (error: any) {
  //     setOpen(true);
  //     setType("error");
  //     setMessage(
  //       error.response?.data?.message ||
  //         error.message ||
  //         "An error occurred while send invoice mail."
  //     );
  //   }
  // };

  return (
    <Grid item xs={12}>
      <Paper elevation={1} sx={{ p: "16px", borderRadius: "12px" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          {selectedIndex === 6 ? (
            <Typography variant="h5">All Invoices</Typography>
          ) : (
            <Typography variant="h5" sx={{ pb: "18px", float: "left" }}>
              Latest Invoice
            </Typography>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                  />
                  {rows !== null && rows.length > 0 ? (
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .filter(
                          (i) =>
                            i.invoiceCode
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.customerName
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.createdDate
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.dueDate
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.status
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.id?.toString().includes(text)
                        )
                        .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          //const isItemSelected = isSelected(row.invoiceCode);
                          //const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              sx={{ width: "max-content" }}
                              // onClick={(event) =>
                              //   handleClick(event, row.invoiceCode)
                              // }
                              // role="checkbox"
                              //aria-checked={isItemSelected}
                              //tabIndex={-1}
                              key={index}
                              //selected={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell> */}
                              <TableCell align="left">
                                <Box
                                  alignItems="center"
                                  display="flex"
                                  width="max-content"
                                >
                                  <Button
                                    sx={{
                                      p: 0,
                                      ":hover": {
                                        background: "none",
                                      },
                                    }}
                                    onClick={() => handleModalOpen(row)}
                                  >
                                    {row.invoiceCode}
                                  </Button>
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                {row.customerName}
                              </TableCell>
                              <TableCell align="left">{row.amount}</TableCell>
                              <TableCell align="left">
                                {row.createdDate}
                              </TableCell>
                              <TableCell align="left">{row.dueDate}</TableCell>
                              <TableCell align="left">
                                <Chip
                                  color={
                                    row.status === "Paid"
                                      ? "success"
                                      : row.status === "Draft"
                                      ? "info"
                                      : row.status === "Cancel"
                                      ? "error"
                                      : "warning"
                                  }
                                  variant="outlined"
                                  sx={{ border: "2px solid" }}
                                  label={
                                    row.status === "Paid"
                                      ? "Paid"
                                      : row.status === "Draft"
                                      ? "Draft"
                                      : row.status === "Cancel"
                                      ? "Cancel"
                                      : "Pending"
                                  }
                                  onClick={() => updateStatus(row)}
                                />
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  startIcon={<ShareIcon />}
                                  className="custom-btn2"
                                  sx={{
                                    ":hover": {
                                      background: "none",
                                    },
                                  }}
                                  onClick={() => handleModalOpen1(row)}
                                ></Button>

                                <Button
                                  startIcon={<EditIcon />}
                                  className="custom-btn2"
                                  onClick={async () => {
                                    if (
                                      row.status === "Paid" ||
                                      row.status === "Cancel"
                                    ) {
                                      alert(
                                        "Cannot edit paid / cancel invoice"
                                      );
                                    } else {
                                      await (dispatch as any)(
                                        getInvoiceData(row.invoiceCode, token)
                                      );
                                      dispatch({
                                        type: CHANGE_INDEX,
                                        value: 5,
                                      });
                                      navigate("/invoice");
                                    }
                                  }}
                                  sx={{
                                    ":hover": {
                                      background: "none",
                                    },
                                  }}
                                ></Button>
                                <Button
                                  startIcon={<GetAppIcon />}
                                  className="custom-btn2"
                                  sx={{
                                    ":hover": {
                                      background: "none",
                                    },
                                  }}
                                  onClick={() => handleDownload(row)}
                                ></Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      <PopupModal
                        open={modalOpen}
                        data={invoiceData}
                        handleClose={handleModalClose}
                      />
                      <ShareModel
                        open={modalOpen1}
                        data={invoiceData}
                        handleClose={handleModalClose1}
                      />
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={7}
                          align="center"
                          style={{ padding: "150px" }}
                        >
                          No data available
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{
                  disabled: rows?.length === 0,
                }}
                // Disable "previous" button if we are on the first page
                backIconButtonProps={{
                  disabled: pageNumber <= 1,
                }}
                labelDisplayedRows={({ from, to }) => null}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {open ? (
        <SnackBar onClose={handleClose} data2={type} data3={message} />
      ) : (
        ""
      )}
    </Grid>
  );
};

export default DashBoardTable;
