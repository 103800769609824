import {Grid } from "@mui/material";
import "../Styles/PagesStyle/index.css";
import CreateForm from "../Pages/Invoice/InvoiceForm";

 const InvoicesPage = () => {
 return (
       <Grid item xs={12} md={12}>
        <CreateForm />
       </Grid>
 );
 }

 export default InvoicesPage;



  
