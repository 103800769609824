export const formatPhoneNumber = (value: string) => {
  const onlyNumbers = value.replace(/[^\d]/g, "");
  return onlyNumbers;
};

export const formatName = (value: string) => {
  const onlyAlphabets = value.replace(/[0-9]/g, "");
  return onlyAlphabets;
};

export const formatCardNumber = (value: string) => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, "");

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter((group) => !!group).join(" ")
  );
};

export const formatMedicareCardNumber = (value: string) => {
  const regex = /^(\d{0,4})(\d{0,5})(\d{0,1})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, "");
  // const onlyNumbers = (value: string) => /^[A-Za-z]*$/.test(value);

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter((group) => !!group).join(" ")
  );
  // return onlyNumbers;
};

export const isLetters = (str: string) => /^[0-9]*$/.test(str);
export const isCharacters = (value: string) => /^[A-Za-z]*$/.test(value);

// export const formatDate = (value: string) => {
//   const regex = /^(\d{0,2})(\d{0,2})$/g;
//   const onlyNumbers = value.replace(/[^\d]/g, "");

//   return onlyNumbers.replace(regex, (regex, $1, $2) =>
//     [$1, $2].filter((group) => !!group).join("/")
//   );
// };
export const formatReference = (value: string | number) => {
  const stringValue = String(value);
  const onlyNumber = stringValue.replace(/\D/g, "");
  const singleDigit = onlyNumber.substring(0, 1);
  return singleDigit;
};

interface LengthOfStay {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const formatTime = (lengthOfStay: LengthOfStay): string => {
  const { days, hours, minutes, seconds } = lengthOfStay;

  if (days > 0) {
    return `${days}:${hours}:${minutes}:${seconds}`;
  } else if (hours > 0) {
    return `${hours}:${minutes}:${seconds}`;
  } else if (minutes > 0) {
    return `${minutes}:${seconds}`;
  } else {
    return `${seconds}`;
  }
};

export const formatTableDate = (inputDate: string): string => {
  const date = new Date(inputDate);
  const day = date.getDate().toString();
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
};

  export const formatTimestampTo24Hour = (timestampStr: string): string => {
    const date = new Date(timestampStr);

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

export const formatValue = (value: string) => {
  const onlyNumbersAndDecimal = value.replace(/[^\d.]/g, ""); // Allow only digits and decimal point

  // If a decimal point is present, ensure that only one decimal point remains
  const parts = onlyNumbersAndDecimal.split('.');
  if (parts.length > 2) {
    parts.pop();
  }
  const formattedInput = parts.join('.') || '';

  return formattedInput;
};

export const replaceUnderscoresWithSpaces = (inputString:string):string => {
  return inputString.replace(/_/g, " ");
};

export  const formatInvoiceDate = (dateString:string):string => {
  const date = new Date(dateString);
  const options:any = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export const generateInvoiceNumber = async (prefix:string) => {
    
      const suffix = (await Math.floor(Math.random() * 9999999)) + 1;
      const paddedSuffix = String(suffix).padStart(7, "0");
      const invoiceNumber = `${prefix}${paddedSuffix}`;
      return invoiceNumber;
}


export const formatInput = (input:string) => {
  return input.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const formatToUpper = (str:string) => {
  return str.toUpperCase();
};


export const formatDate= (inputDateString:string)=> {
  const inputDate = new Date(inputDateString);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}






