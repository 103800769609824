// material ui
import {
  Container,
  TextField,
  Typography,
  Button,
  Box,
  FormHelperText,
  Grid,
} from "@mui/material";

// third party libraries
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// project import
import "../../Styles/UserformStyle.css";
import { APP_LOGO } from "../../constant";
import { AuthState } from "../../store/rootReducer";
import { register } from "../../store/auth/actions";
import AlertBar from "../../components/Alert";
import PasswordStrengthChecker from "../../components/PasswordStrengthChecker";
import { useState } from "react";

 const formatPhoneNumber = (value: string) => {
   const onlyNumbers = value.replace(/[^\d]/g, "");
   return onlyNumbers;
 };

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name Is required"),
  email: Yup.string().email().required("Email is required"),
  companyName: Yup.string().required("Company name is required"),
  password: Yup.string().required("Password is required"),
  phoneNumber: Yup.string().required("Phone number is required").max(10).min(10),
});

const UserRegistrationForm = () => {
  const[open ,setOpen]= useState(false);
  const dispatch = useDispatch();
  const error = useSelector((state: AuthState) => state.auth.error);
  const navigate = useNavigate();

 
  const handleFocus= () =>{
    setOpen(true);
  }
  const handleBlurOut=()=>{
    setOpen(false);
    
  }

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNumber: "",
        companyName: "",
      }}
      validationSchema={validationSchema}
      onSubmit={ async (inputs: any) => {
       try {
         let userData: any = { ...inputs };
         console.log(userData);
         await(dispatch as any)(register(userData));
       } catch (error) {
         console.error("An error occurred during registration:", error);
       }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }: any) => (
        <Container
          className="bg-image"
          maxWidth={false}
          sx={{ height: "100vh", overflow: "auto" }}
        >
          <Box className="register2-form" sx={{ paddingBottom: "15px" }}>
            <div>
              <img className="logo-image" src={APP_LOGO} alt="logo"></img>
            </div>
            <div className="top-text1">
              <Typography variant="h5">Sign up to Invoicing</Typography>
            </div>
            <form className="form-input-box" onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    required
                    id="first-name"
                    name="firstName"
                    label="First name"
                    fullWidth
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ mt: "16px" }}
                  />
                  {touched.firstName && errors.firstName && (
                    <FormHelperText error id="first-name-helper-text">
                      {errors.firstName}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    required
                    id="last-name"
                    name="lastName"
                    label="Last name"
                    fullWidth
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ mt: "16px" }}
                  />
                  {touched.lastName && errors.lastName && (
                    <FormHelperText error id="last-name-helper-text">
                      {errors.lastName}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Email"
                    fullWidth
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ mt: "16px" }}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="email-helper-text">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    required
                    id="company-name"
                    name="companyName"
                    label="company name"
                    fullWidth
                    value={values.companyName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ mt: "16px" }}
                  />
                  {touched.companyName && errors.companyName && (
                    <FormHelperText error id="company-name-helper-text">
                      {errors.companyName}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <PasswordStrengthChecker
                    data2={errors}
                    data3={touched}
                    data={open}
                    onBlur={handleBlurOut}
                    onFocus={handleFocus}
                    value={values.password}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    required
                    id="phone-number"
                    name="phoneNumber"
                    label="Phone Number "
                    inputProps={{ maxLength: 10 }}
                    fullWidth
                    value={formatPhoneNumber(values.phoneNumber)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ mt: "16px" }}
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <FormHelperText error id="phone-number-helper-text">
                      {errors.phoneNumber}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              {/* <TextField
                required
                id="matching-password"
                name="matchingPassword"
                label="Confirm Password"
                type="password"
                fullWidth
                value={values.matchingPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mt: "16px" }}
              />
              {touched.matchingPassword && errors.matchingPassword && (
                <FormHelperText error id="matching-password-helper-text">
                  {errors.matchingPassword}
                </FormHelperText>
              )} */}

              <div className="bottom-text">
                <Typography variant="caption" display="block">
                  By signing up, you agree and accept our{" "}
                  <span>
                    <Link
                      to="/terms_and_conditions"
                      style={{ color: "#1565C0", textDecoration: "none" }}
                    >
                      Terms and Conditions
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    <Link
                      to="/privacy_policy"
                      style={{ color: "#1565C0", textDecoration: "none" }}
                    >
                      Privacy Policy.
                    </Link>
                  </span>
                </Typography>
              </div>
              {error != null ? <AlertBar message={error} /> : ""}
              <Button
                className="custom-btn"
                variant="contained"
                sx={{
                  fontSize: "15px",
                  width: "50%",
                  height: "42px",
                  margin: "0 25%",
                }}
                type="submit"
              >
                Signup
              </Button>
              <div>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    margin: "1% 25%",
                    float: "none !important",
                    fontSize: "16px",
                    color: "#595959",
                  }}
                >
                  Already a member?
                  <span>
                    <Button variant="text" onClick={() => navigate("/login")}>
                      Login
                    </Button>
                  </span>
                </Typography>
              </div>
            </form>
          </Box>
        </Container>
      )}
    </Formik>
  );
};

export default UserRegistrationForm;
