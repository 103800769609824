import React, { useEffect } from "react";
import { ExpandLess, ExpandMore, Menu, MenuOpen } from "@mui/icons-material";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import { useNavigate, Link } from "react-router-dom";
import "../Styles/DashBoard.css";


// project import
import {
  ACCOUNT_ICON,
  BOOKING_ICON,
  HOME_ICON,
  WMS_ONE_ICON,
  APP_LOGO,
  TRIAGE_ICON,
  REGISTRATION_ICON,
  REPORTS_ICON,
  SETTINGS_ICON,
} from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../store/rootReducer";
import { CHANGE_INDEX } from "../store/sideBarIndex/actionTypes";

//style for drawer/////////
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  whiteSpace: "nowrap",
  minHeight: "100vh",
  border: "none",
  overflow: "hidden",
  // background: `url(${MENU_BG_IMAGE}) no-repeat center center / cover`,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  minHeight: "100vh",
  overflow: "inherit",
  border: "none",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#EAF1FB",
  height: "auto!important",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiPaper-root": {
      position: "absolute",
      backgroundColor: "#EAF1FB",
    },
    "& .MuiDrawer-paper": openedMixin(theme),
    overflow: "inherit",
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiPaper-root": {
      // background: `url(${MENU_BG_IMAGE}) no-repeat center center / cover`,
      position: "absolute",
      backgroundColor: "#EAF1FB",
    },
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

//-------end of style------//
type drawerProps = {
  open: boolean;
  handleDrawerOpen: Function;
};

export default function MiniDrawer({ open, handleDrawerOpen }: drawerProps) {
  const dispatch = useDispatch();
   const navigate = useNavigate();
  const [expand, setExpand] = React.useState(false);
 
  const selectedIndex: any = useSelector(
    (state: AuthState) => state.sideBarReducer
  );

  const itemButtonStyle = {
    marginBottom: "12px",
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
  };

  const handleClick = () => {
    setExpand(!expand);
  };

  const handleListItemClick = (
    e: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    dispatch({ type: CHANGE_INDEX, value: index });
   
  };
  useEffect(()=>{
    if (selectedIndex === 0 ){
      navigate("/homepage");
    }
    
  },[])

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            minHeight: expand ? "860px" : "100vh",
            overflow: expand ? "inherit" : "hidden",
          },
        }}
      >
        <List sx={{ paddingBottom: expand ? "0px" : "8px" }}>
          <Link to="/" style={{ textDecoration: "none" }}>
            {open ? (
              <Box
                className="sideMenu-logo"
                component="img"
                alt="Logo"
                src={APP_LOGO}
              />
            ) : (
              <Box
                className="sideMenu-icon"
                component="img"
                alt="Icon"
                src={WMS_ONE_ICON}
              />
            )}
            <span style={{ paddingTop: "35px", position: "absolute" }}>
              Invoice
            </span>
          </Link>
        </List>
        <List>
          <ListItemButton disableRipple sx={{ ...itemButtonStyle }}>
            <IconButton
              disableRipple
              onClick={() => {
                handleDrawerOpen();
                setExpand(false);
              }}
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {open ? <MenuOpen sx={{ marginLeft: "-6px" }} /> : <Menu />}
            </IconButton>
          </ListItemButton>
          <>
            <ListItemButton
              sx={{ ...itemButtonStyle }}
              selected={selectedIndex === 0}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                handleListItemClick(e, 0)
              }
              component={Link}
              to={"/homepage"}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  className="menu-icons"
                  alt="booking_icon"
                  src={HOME_ICON}
                />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 1}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                handleListItemClick(e, 1)
              }
              component={Link}
              to={"/customers"}
              sx={{ ...itemButtonStyle }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  className="menu-icons"
                  alt="booking_icon"
                  src={BOOKING_ICON}
                />
              </ListItemIcon>
              <ListItemText
                primary="Customers"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            <ListItemButton
              sx={{ ...itemButtonStyle }}
              selected={selectedIndex === 2}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                handleListItemClick(e, 2)
              }
              component={Link}
              to={"/vendors"}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  className="menu-icons"
                  alt="Registration"
                  src={BOOKING_ICON}
                />
              </ListItemIcon>
              <ListItemText primary="Vendors" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 3}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                handleListItemClick(e, 3)
              }
              component={Link}
              to={"/products"}
              sx={{ ...itemButtonStyle }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  className="menu-icons"
                  alt="patientIcon"
                  src={TRIAGE_ICON}
                />
              </ListItemIcon>
              <ListItemText primary="Products" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 4}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                handleListItemClick(e, 4)
              }
              component={Link}
              to={"/invoices"}
              sx={{ ...itemButtonStyle }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  className="menu-icons"
                  alt="reportIcon"
                  src={REPORTS_ICON}
                />
              </ListItemIcon>
              <ListItemText primary="Invoices" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>

            <ListItemButton
              selected={selectedIndex === 5}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                handleListItemClick(e, 5)
              }
              component={Link}
              to={"/invoice"}
              sx={{ ...itemButtonStyle }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  className="menu-icons"
                  alt="reportIcon"
                  src={REGISTRATION_ICON}
                />
              </ListItemIcon>
              <ListItemText
                primary="Create Invoice"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            <ListItemButton sx={{ ...itemButtonStyle, marginBottom: "0px" }}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={() => {
                  handleDrawerOpen();
                  setExpand(false);
                }}
              >
                <Box
                  component="img"
                  className="menu-icons"
                  alt="settingIcon"
                  src={SETTINGS_ICON}
                />
              </ListItemIcon>
              <ListItemText
                primary="Configurations"
                sx={{ opacity: open ? 1 : 0 }}
                onClick={handleClick}
              />
              {open ? (
                expand ? (
                  <ExpandLess onClick={handleClick} />
                ) : (
                  <ExpandMore onClick={handleClick} />
                )
              ) : (
                ""
              )}
            </ListItemButton>

            <Collapse in={expand} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  selected={selectedIndex === 6}
                  sx={{ pl: "20px" }}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    handleListItemClick(e, 6)
                  }
                  component={Link}
                  to={"/companyDetails"}
                >
                  <ListItemText sx={{ pl: 6 }} primary="Company Details" />
                </ListItemButton>
                <ListItemButton
                  selected={selectedIndex === 7}
                  sx={{ pl: "20px" }}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    handleListItemClick(e, 7)
                  }
                  component={Link}
                  to={"/layoutConfig"}
                >
                  <ListItemText sx={{ pl: 6 }} primary="Templates" />
                </ListItemButton>
                {/* <ListItemButton
                  selected={selectedIndex === 9}
                  sx={{ pl: "20px" }}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                    handleListItemClick(e, 9)
                  }
                  component={Link}
                  to={"/addons"}
                >
                  <ListItemText sx={{ pl: 6 }} primary="Add-ons" />
                </ListItemButton> */}
              </List>
            </Collapse>

            <ListItemButton
              selected={selectedIndex === 8}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                handleListItemClick(e, 8)
              }
              component={Link}
              to={"/myAccount"}
              sx={{ ...itemButtonStyle, marginTop: "12px" }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Box
                  component="img"
                  className="menu-icons"
                  alt="accountIcon"
                  src={ACCOUNT_ICON}
                />
              </ListItemIcon>
              <ListItemText
                primary="My Account"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </>
        </List>
      </Drawer>
    </Box>
  );
}
