import { Box, FormHelperText } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";


function StrengthMeter({
  poorPassword,
  weakPassword,
  strongPassword,
  passwordError,
}: any) {
   

     
  return (
    <Box sx={{ mt: "5px" }}>
      {poorPassword === true ? (
        <>
          <LinearProgress
            variant="determinate"
            color="error"
            sx={{ backgroundColor: "#E0E0E0" }}
            value={30}
          />
          <FormHelperText sx={{ color: "red" }}>{passwordError}</FormHelperText>
        </>
      ) : (
        ""
      )}
      {weakPassword === true ? (
        <>
          <LinearProgress
            variant="determinate"
            color="warning"
            sx={{ backgroundColor: "#E0E0E0" }}
            value={65}
          />
          <FormHelperText sx={{ color: "orange" }}>
            {passwordError}
          </FormHelperText>
        </>
      ) : (
        ""
      )}
      {strongPassword === true ? (
        <>
          <LinearProgress
            variant="determinate"
            color="success"
            sx={{ backgroundColor: "#E0E0E0" }}
            value={100}
          />
          <FormHelperText sx={{ color: "green" }}>
            {passwordError}
          </FormHelperText>
        </>
      ) : (
        ""
      )}
    </Box>
  );
}

export default StrengthMeter;
