import { useContext, useEffect, useState } from "react";
import '../../Styles/PagesStyle/index.css';

// material-ui
import { Add} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";

// project import

import { CustomerContext } from "./CustomerPage";
//import axios from "axios";
import common from "../../config.json";
import SnackBar from "../../components/Snackbar";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";


 

export interface Data {
  id: number;
  customerCode: string;
  status: number;
  name: string;
  phone: string;
  email: string;
  gstNo: string;
  address: string;
  country: string;
  state: string;
  city: string;
  pinCode: string;
  address1:string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "customerCode",
    numeric: false,
    disablePadding: false,
    label: "Customer Code",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },

  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Actions",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox" sx={{ paddingLeft: "4px !important" }}>
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            // onChange={}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            disabled={true}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant="body1">{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={{ display: "none" }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CustomerTable = () => {
  const authUser: any = useAuthUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [text, setText] = useState("");
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>("name");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const { setPage, setAddCustomer, setUser } = useContext(CustomerContext);
  const [rows, setRows] = useState<Data[]>([]);
 // const [guid, setGuid] = useState("");
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const token = authUser()?.token;
 
  useEffect(() => {
    getAllCustomersData();
  }, [rowsPerPage, pageNumber,refresh]);

  const getAllCustomersData = async () => {
    let baseUrl = common.BASE_URL + common.CUSTOMERs_API + "/customers";
   
    try {
      const response = await axios.get(
        baseUrl,

        {
          params: {
            recordsPerPage: rowsPerPage,
            pageNumber: pageNumber,
          },
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const transformedData: Data[] = response.data.data.map((item: any) => ({
        id: item.id,
        customerCode: item.customer_code,
        name: item.customer_name,
        email: item.email,
        gstNo: item.gst_number,
        phone: item.phone_number,
        address:item.s_address,
        address1: `${item.s_address}, ${item.s_city}, ${item.s_state}, ${item.s_country}, ${item.s_postalcode}`,
        country: item.s_country,
        state: item.s_state,
        city: item.s_city,
        pinCode: item.s_postalcode,
        status: item.status === 1 ? "Active" : "Inactive",
      }));
      setRows(transformedData);
      console.log(rows);
    } catch (error) {
      console.log("error", error);
    }
  };

 

  console.log(rows);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen(false);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("row", event.target.value);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.customerCode);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (
  //   // eslint-disable-next-line no-unused-vars
  //   event: React.MouseEvent<unknown>,
  //   name: string
  // ) => {
   

  //   if (selected.length === 0) {
  //     const data = selected.concat(name)
  //     //setGuid(name);
  //     return setSelected(data);
  //   }
  //   if (selected.includes(name)) {
  //     return setSelected([]);                         
  //   } else {
  //     return selected
  //   }
  // };



  const deleteCustomer = async (customerCode:string) => {
    let baseUrl = common.BASE_URL + common.CUSTOMERs_API + `/delete/${customerCode}`;

    try {
      const response = await axios
        .get(baseUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            setOpen(true);
            setType("success");
            setMessage("You have successfully deleted customer.");
            setRefresh(!refresh);
          }
        });
    } catch (error:any) {
          setOpen(true);
          setType("error");
          setMessage(error.response.data.message ||error.response.statusText);
     
     
    }
  };

  // const activateStaff = async () => {
  //   if(guid){
  //   let baseUrl = "";
  //   if (process.env.NODE_ENV !== "production") {
  //     baseUrl = common.STAFF_API_URL + `/activate/guid/${guid}`;
  //   } else {
  //     baseUrl = common.BASE_URL + common.STAFF_API_URL + `/activate/guid/${guid}`;
  //   }

  //   try {
  //     const response = await axios
  //       .put(baseUrl,null, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + token,
  //         },
  //       })
  //       .then(function (response) {
  //         if (response.status === 200) {
  //           setOpen(true);
  //           setType("success");
  //           setMessage("You have successfully activated staff.");
  //           setAnchorEl(null);
  //           setRefresh(!refresh);
  //         }
  //       });
  //     console.log(response);
  //   } catch (error:any) {
  //         setOpen(true);
  //         setType("error");
  //         setMessage(error.response.data.message||error.response.statusText);
       
  //     setAnchorEl(null);
  //   }
  // }
  // };

  



  return (
    <Container maxWidth={false} disableGutters={false}>
      <Grid container spacing={0}>
        <Paper
          elevation={0}
          className="page-container-paper"
          sx={{
            borderRadius: "16px",
            minWidth: "480px",
          }}
        >
          <Typography variant="h5" sx={{ pb: "32px" }}>
            Customers
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={1}
                //alignItems="center"
                justifyContent="space-between"
              >
                <TextField
                  label="Search"
                  value={text}
                  size="small"
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Name, email, etc..."
                  InputLabelProps={{ shrink: true }}
                  sx={{ minWidth: "300px" }}
                />
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ pb: 1 }}
                >
                  <Button
                    className="custom-btn"
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => setAddCustomer(true)}
                  >
                    Add Customer
                  </Button>
                  {/* <IconButton
                    aria-controls="profile-toggle"
                    onClick={handleProfileMenuOpen}
                  >
                    <MoreVert />
                  </IconButton> */}
                </Stack>
                {/* {renderMenu} */}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-labelledby="tableTitle" size="medium">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                  />
                  {rows !== null && rows.length > 0 ? (
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .filter(
                          (i) =>
                            i.name
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.customerCode
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.phone
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.email
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.address1
                              ?.toLowerCase()
                              .includes(text.toLowerCase()) ||
                            i.status ||
                            i.id?.toString().includes(text)
                        )
                        .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          // const isItemSelected = isSelected(row.customerCode);
                          // const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              sx={{ width: "max-content" }}
                              // onClick={(event) =>
                              //   handleClick(event, row.customerCode)
                              // }
                              // role="checkbox"
                              // aria-checked={isItemSelected}
                              // tabIndex={-1}
                              key={index}
                              //selected={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell> */}
                              <TableCell align="left">
                                <Box
                                  alignItems="center"
                                  display="flex"
                                  width="max-content"
                                >
                                  <Button
                                    sx={{
                                      p: 0,
                                      color: "#714b67",
                                      ":hover": {
                                        background: "none",
                                      },
                                    }}
                                    onClick={() => {
                                      setPage("profile");
                                      setUser(row);
                                    }}
                                  >
                                    {row.customerCode}
                                  </Button>
                                </Box>
                              </TableCell>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">{row.phone}</TableCell>
                              <TableCell align="left" style={{ width: "20%" }}>
                                {row.address1}
                              </TableCell>
                              <TableCell align="left">
                                <Chip
                                  color={
                                    row.status !== 1 ? "success" : "default"
                                  }
                                  variant="outlined"
                                  sx={{ border: "2px solid" }}
                                  label={
                                    row.status !== 1 ? "Active" : "Inactive"
                                  }
                                />
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  startIcon={<VisibilityIcon />}
                                  className="custom-btn2"
                                  onClick={() => {
                                    setPage("profile");
                                    setUser(row);
                                  }}
                                  sx={{
                                    ":hover": {
                                      background: "none",
                                    },
                                  }}
                                ></Button>
                                <Button
                                  startIcon={<EditIcon />}
                                  className="custom-btn2"
                                  onClick={() => {
                                    setAddCustomer(false);
                                    setPage("edit");
                                    setUser(row);
                                  }}
                                  sx={{
                                    ":hover": {
                                      background: "none",
                                    },
                                  }}
                                ></Button>
                                <Button
                                  onClick={() => deleteCustomer(row.customerCode)}
                                  startIcon={<DeleteOutlineIcon />}
                                  className="custom-btn2"
                                  sx={{
                                    ":hover": {
                                      background: "none",
                                    },
                                  }}
                                ></Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={7}
                          align="center"
                          style={{ padding: "150px" }}
                        >
                          No data available
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{
                  disabled: rows?.length === 0,
                }}
                // Disable "previous" button if we are on the first page
                backIconButtonProps={{
                  disabled: pageNumber <= 1,
                }}
                labelDisplayedRows={({ from, to }) => null}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {open ? (
        <SnackBar onClose={handleClose} data2={type} data3={message} />
      ) : (
        ""
      )}
    </Container>
  );
};

export default CustomerTable;
