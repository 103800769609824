import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import { formatInvoiceDate } from "../../ValidationPage";
 import {WMS_ONE_ICON} from '../../../constant'

const MyDocument = ({ invoiceData, styles }: any) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Tax Invoice</Text>
        <View style={styles.companyInfo}>
          <View>
            <View style={styles.billTime}>
              <Text>
                Invoice Number:{""}
                {invoiceData.invoiceNumber ? invoiceData.invoiceNumber : "N/A"}
              </Text>
              <Text>
                Created Date:{" "}
                {invoiceData.createdDate
                  ? formatInvoiceDate(invoiceData.createdDate)
                  : "N/A"}
              </Text>
              <Text>
                Due Date:{" "}
                {invoiceData.dueDate
                  ? formatInvoiceDate(invoiceData.dueDate)
                  : "N/A"}
              </Text>
            </View>
            {/* {invoiceData.companyDetails && invoiceData.companyDetails.logo && (
              <Image
                src={WMS_ONE_ICON} // Assuming the image format is PNG
                style={styles.logo}
              />
            )} */}
            <Text>
              {invoiceData.companyDetails && invoiceData.companyDetails.name}
            </Text>
            <Text>
              {invoiceData.companyDetails && invoiceData.companyDetails.address}
            </Text>
            <Text>
              {invoiceData.companyDetails && invoiceData.companyDetails.country}
            </Text>
            <Text>
              GST:{""}
              {invoiceData.companyDetails.gstNo
                ? invoiceData.companyDetails.gstNo
                : ""}
            </Text>
          </View>
          <View style={styles.recipientInfo}>
            <Text>Billing to:{""}</Text>
            <Text>
              {invoiceData.customerName
                ? invoiceData.customerName
                : invoiceData.billingDetails.name}
            </Text>
            <Text>
              {invoiceData.customerName
                ? ""
                : invoiceData.billingDetails.address}
            </Text>
            <Text>
              {invoiceData.customerName
                ? ""
                : invoiceData.billingDetails.country}
            </Text>
            {invoiceData.customerName ? (
              ""
            ) : (
              <Text>
                GST:{""}
                {invoiceData.billingDetails.gstNo &&
                  invoiceData.billingDetails.gstNo}
              </Text>
            )}
          </View>
        </View>
        <View style={styles.itemList}>
          <View style={styles.tableHeader}>
            <Text style={{ flex: 3 }}>Description</Text>
            <Text style={{ flex: 2 }}>HSN/SAC</Text>
            <Text style={{ flex: 1 }}>Unit Price</Text>
            <Text style={{ flex: 1 }}>Quantity</Text>
            <Text style={{ flex: 1 }}>Taxes</Text>
            <Text style={{ flex: 1 }}>Amount</Text>
          </View>
          {invoiceData.items &&
            invoiceData.items.map((item: any, index: any) => (
              <View
                style={[
                  styles.tableRow,
                  index % 2 !== 1 ? styles.stripedRow : null,
                ]}
                key={index}
              >
                <Text style={{ flex: 3 }}>{item.pname}</Text>
                <Text style={{ flex: 2 }}>{item.hsn}</Text>
                <Text style={{ flex: 1 }}>{item.price}</Text>
                <Text style={{ flex: 1 }}>{item.quantity}</Text>
                <Text style={{ flex: 1 }}>{item.tax}</Text>
                <Text style={{ flex: 1 }}>{item.amount}</Text>
              </View>
            ))}
          <View style={styles.totalRow}>
            <Text style={{ fontWeight: "bold" }}>Untaxed Amount:</Text>
            <Text> {invoiceData.totalAmount}</Text>
          </View>
          <View style={styles.totalRow2}>
            <Text>SGST(9%):</Text>
            <Text>{invoiceData.sgst}</Text>
          </View>
          <View style={styles.totalRow2}>
            <Text>CGST (9%):</Text>
            <Text>{invoiceData.cgst}</Text>
          </View>
          <View style={styles.totalRow}>
            <Text style={{ fontWeight: "bold" }}>Total Amount:</Text>
            <Text> {invoiceData.taxedTotalAmount}</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
