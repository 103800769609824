import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useState } from "react";

function PasswordInputField({
  passwordError,
  touched,
    handlePasswordBlur,
    handlePasswordFocus,
  handlePasswordChange,
  passwordValue,
  passwordStrength,
}: any) {
   const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <TextField
        required
        id="password"
        name="password"
        label="Password"
        fullWidth
        onInput={passwordStrength}
        value={passwordValue}
        onChange={handlePasswordChange}
        onFocus={handlePasswordFocus}
        onBlur={handlePasswordBlur}
        type={showPassword ? "text" : "password"}
        sx={{ mt: "16px" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {touched?.password && passwordError?.password && (
        <FormHelperText error id="password-helper-text">
          {passwordError}
        </FormHelperText>
      )}
    </>
  );
}

export default PasswordInputField;
