import {
  Box,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
    styled,
} from "@mui/material";
import { APP_LOGO } from "../constant";
  
const CustomListItemText = styled(ListItemText)((theme) => ({
  ".MuiTypography-root": {
    fontWeight: "bold",
    fontSize: "24px"
  }
}))

const CustomListItem = styled(ListItem)((theme) => ({
  "::marker" : {
    fontWeight: "bold",
    fontSize: "24px"
  }
}))
  
  const PrivacyPolicy = () => {
   
    return (
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        height="100%"
        justifyContent="center"
      >
        <Grid container spacing={0} maxWidth="850px">
          <Paper
            elevation={0}
            className="page-container-paper"
            sx={{
              borderRadius: "16px",
              width: "100%",
              px: { xs: "16px", sm: "32px", md: "64px" },
            }}
          >
            <Grid item xs={12}>
              <img
                alt="medconnect-logo"
                src={APP_LOGO}
                height="100px"
                style={{ paddingBottom: "16px" }}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: "6px" }}>
              <Typography variant="h3">Terms and Conditions</Typography>
            </Grid>
            <Grid item xs={12}>
              <List sx={{ listStyle: "decimal", pl: 4 }}>
                {/* lower-alpha */}
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Overview" />
                  <Typography variant="body1">
                    <b>MedConnect AI Solutions Pty Ltd </b>(
                    <b>MedConnect, we, us, our</b>) considers your privacy to be
                    important. This Privacy Policy (<b>Policy</b>) sets out how
                    we:
                  </Typography>
                  <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText
                        primary="collect, hold, use and disclose information about individuals who deal or interact
with us; and"
                      />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="assist the users of our software to maintain patient confidentiality." />
                    </ListItem>
                  </List>
                </CustomListItem>
                <ListItem>
                  <Typography variant="body1">
                    We treat all personal information collected by us in
                    accordance with this Policy and applicable privacy laws in
                    the jurisdictions in which we operate or in which our users
                    are located including, but not limited to, the Australian{" "}
                    <i>Privacy Act 1988</i> (Cth) (<b>Privacy Act</b>) (the
                    Privacy Act together with other applicable privacy laws,{" "}
                    <b>Privacy Laws</b>).{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    If there is any inconsistency between applicable Privacy
                    Laws and this Policy, the applicable Privacy Laws will
                    prevail to the extent of the inconsistency.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    {" "}
                    By creating an account with us, filling out a contact form
                    or otherwise engaging with us or providing information to
                    us, you consent to us collecting, processing, and using your
                    personal information in accordance with this Policy.{" "}
                  </Typography>
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Patient Confidentiality" />
                </CustomListItem>
                <ListItem>
                  <Typography variant="body1">
                    MedConnect offers end-to-end patient care and practise
                    management software, with focus on AI and automation
                    enabling optimal human to system interaction and no manual
                    processes (<b>Software</b>)
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    MedConnect customers are healthcare practitioners. Only
                    individuals authorised by the relevant customer will become
                    registered users within our Software environment (
                    <b>Software Users</b>).
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    Software Users may utilise our Software to record, store,
                    access and communicate your personal information in order to
                    provide healthcare services to you.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    Our customers and Software Users are solely responsible for:
                  </Typography>
                </ListItem>
                <ListItem>
                  <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText
                        primary="the collection, use and disclosure of any patient data, which may include your
personal data; and"
                      />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText
                        primary="obtaining patient permission to release or disclose patient data, which may include
your personal data, to us."
                      />
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    We recommend that you check the privacy and security
                    policies and procedures of any third party to whom you
                    provide personal information.{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    If you provide any information to us, which may include
                    personal data, you warrant and represent that you are
                    entitled to do so.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    We accept no liability or responsibility whatsoever in
                    relation to the collection, use and disclosure of
                    information by any other party, including patient data
                    collected, used and disclosed by our customers or Software
                    Users.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    Where MedConnect manages information on behalf of Software
                    Users, we do so in accordance with Privacy Laws and the
                    contractual arrangements we have with our customers.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    We do not directly collect personal information of
                    healthcare patients or deal directly with any healthcare
                    patients. Any queries from patients or other third parties
                    regarding personal data recorded or stored using our
                    Software will be referred to the relevant Software User.
                  </Typography>
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Personal Information Collected by Us" />
                </CustomListItem>
                <ListItem>
                  <Typography variant="body1">
                    Personal information is information or an opinion of an
                    individual whose identity is apparent or can be reasonably
                    ascertained. As part of our business, we may collect
                    personal information from you.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    We collect a variety of personal information that is
                    reasonably necessary for the purposes of conducting and
                    improving our business and our products and services. This
                    information may be collected in a number of ways, including:
                  </Typography>
                </ListItem>
                <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    <Typography variant="body1">
                      through our website medconnectai.com and its subdomains
                      (our <b>Site</b>), our Software and various third party
                      social media and messaging sites, pages, groups and
                      forums, and other services relating to our Site
                      (collectively with our Site, our <b>Services</b>);
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="through our Software, including information relating to authorised users of the
Software;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="through marketing research, including surveys and polls;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="from competitions and other user participation events;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="through any of our other business activities or events;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="through a person seeking employment with us and/or by otherwise having some
form of contact with a person, such as by personal contact, mail, telephone, email,
video teleconferencing, Internet or other forms of telecommunication."
                    />
                  </ListItem>
                </List>
                <ListItem>
                  <Typography variant="body1">
                    Personal information collected by us may include:
                  </Typography>
                </ListItem>
                <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="your name;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="your title;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="your contact details (including street address, postal address, email address, phone
number and fax number);"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="location information;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="transactional information (including your method of payment);" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="usernames and passwords that you use to access our Services;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="your reasons for using our Services or any of our products and services;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="your preferences and opinions relating to our Services or any of our products and
services;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="any other information that you choose to send to us; and" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="usage data and logs, which may include information such as your Internet Protocol
(IP) address, browser type, browser version, device type, make and model
clickstream/heatmap data, the pages of our Services that you visit, the features and
functions of our Services you use, activities or actions you do while using our
Services, the time and date of your visit to our Services, the time spent on any pages
of our Services, and other log related information relating to your use of our
Services. 
"
                    />
                  </ListItem>
                </List>
                <ListItem>
                  <Typography variant="body1">
                    Except as outlined in this Policy, we endeavour to only
                    collect personal information about you from you, where it is
                    reasonable and practical to do so. Sensitive information
                    will only be used and disclosed for the purpose for which it
                    was collected by us or for a directly related secondary
                    purpose.
                  </Typography>
                </ListItem>

                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Use of Personal Information" />
                </CustomListItem>
                <ListItem>
                  <ListItemText
                    primary="Generally, we use personal information for providing, evaluating, improving, personalising
and developing our business, our Services, our other products and services, and to protect
our users. More specifically, we use personal information for:"
                  />
                </ListItem>
                <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="providing and delivering our Services, and other products and services to you;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="providing support to you (including technical support) in relation to the use of our
Services and our other products and services;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="improving our Services, and other products and services, and user satisfaction; " />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="promoting and delivering our Services and our other products to organisations and
individuals;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="internal research and statistical purposes (including market segmentation and
customer value analysis) and internal business operations (including fulfilling any
legal requirements); and "
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="enabling us to forward to you other information or material which we believe may
be of interest to you, or which you have indicated you may be interested in
receiving."
                    />
                  </ListItem>
                </List>
                <ListItem>
                  <ListItemText
                    primary="We automatically gather information to monitor the use of our Services and our other
products and services."
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Disclosure of Personal Information" />
                </CustomListItem>
                <ListItem>
                  <Typography variant="body1">
                    5.1 &nbsp;&nbsp;&nbsp;&nbsp;<b>Disclosure generally</b>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="We do not disclose your personal information to any third party, other than those requiring
this information to assist us or those that are directly related to the purposes for which the
information was collected, including:"
                  />
                </ListItem>
                <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="to external service providers, so that they can provide products and services in
connection with the operation and improvement of our business including, without
limitation, our professional advisors, consultants, administrators and data entry
service providers;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="to our affiliates, including but not limited to other organisations and businesses
within our group of organisations, employees and contractors;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="to our affiliates, including but not limited to other organisations and businesses
within our group of organisations, employees and contractors;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="as permitted under the Privacy Laws or any other applicable law;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="if you would reasonably expect that your personal information would be passed to
the person to whom the personal information is disclosed;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="any relevant authority or enforcement body where we reasonably believe that
disclosure is required to bring legal action against a third party;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="entities that we propose to merge with or be acquired by; and" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="with your consent." />
                  </ListItem>
                </List>
                <ListItem>
                  <Typography variant="body1">
                    5.2 &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Third party social media services</b>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="We may operate one of more forums, groups, chat rooms, or other services and facilities
which are hosted on third party social media services and websites, including but not
limited to, Facebook, Twitter, Instagram, Telegram, Slack, Flock, Reddit and LinkedIn."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Your use of, and participation in, such services and facilities are subject always to the terms
of service, acceptable use policies, privacy policies, and other terms and conditions
adopted by those third party social media services and websites."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Any information which you disclose on any third party social media services and websites
is at your own risk and expense. We accept no liability or responsibility whatsoever in
relation to the use, non-use or misuse of such third party social media services and
websites by you or any other person. "
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Protecting Personal Information" />
                </CustomListItem>
                <ListItem>
                  <ListItemText
                    primary="In addition to supporting customers to maintain patient privacy, we take reasonable steps
to protect all personal information which we hold from misuse, interference, loss,
unauthorised access, modification or disclosure. "
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="The precautionary steps we take to protect personal information may include:" />
                </ListItem>
                <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="technologies and processes such as access control procedures including multifactor authentication, network firewalls, physical security and encryption of data
transmission;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="adopting measures to protect our computer systems and networks for storing,
processing and transmitting personal information;"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="ensuring that access to our Software is restricted to authorised users;" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="regularly reviewing our information collection, storage and processing practices;
and"
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="secured encrypted servers, password protection for electronic files, multifactor
authentication procedures, securing paper files in locked cabinets and physical
access restrictions."
                    />
                  </ListItem>
                </List>
                <ListItem>
                  <ListItemText
                    primary="We also take steps to ensure that the personal information we collect is accurate, up-todate and complete. These steps may include maintaining and updating personal
information when it is apparent that updating is required, or we are otherwise advised by
you that your personal information has changed."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Notwithstanding that we use our best endeavours to protect all personal information, we
cannot completely guarantee the security of your personal information."
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Cookies" />
                </CustomListItem>
                <ListItem>
                  <ListItemText
                    primary="Cookies are files with a small amount of data, which may include an anonymous unique
identifier. Cookies are sent to your browser from a website and stored on your computer's
or mobile device’s storage drive."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Like many sites, we use cookies and other similar technologies to collect information and
remember your preferences. You can instruct your browser to refuse all cookies, or to
indicate when a cookie is being sent. However, if you do not accept cookies, you may not
be able to use some features of our Services or our other products and services."
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Web Beacons" />
                </CustomListItem>
                <ListItem>
                  <ListItemText
                    primary="Web beacons (also known as clear gifs, pixel tags or web bugs) are tiny graphics with a
unique identifier, similar in function to cookies, and are used to track the online
movements of users or to access cookies."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Unlike cookies which are stored on the user’s computer or mobile device storage drive,
web beacons are embedded invisibly on web pages (or in e-mail). Web beacons may be
used to deliver or communicate with cookies, to count users who have visited certain pages
and to understand usage patterns."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Like many sites, we use web beacons to collect information which is done in accordance
with this Policy."
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Overseas Disclosure" />
                </CustomListItem>
                <ListItem>
                  <Typography variant="body1">
                    9.1 &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Overseas IT service providers</b>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="We may disclose personal information to outsourced information technology service
providers, including cloud computing providers and data storage providers, and other third
party service providers who are not in Australia or may transfer or store your personal
information outside Australia."
                  />
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    We will take reasonable steps to ensure such overseas
                    recipients do not breach the Australian Privacy Principles
                    contained in the Privacy Act (<b>APPs</b>) or are subject to
                    laws or a scheme substantially similar to the APPs.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Overseas recipients are not bound by the APPs and subclause 8.1 of the APPs does not
apply to the disclosure of your personal information to an overseas recipient. If an overseas
recipient handles your information in breach of the APPs, you will not be able to seek
redress under the Privacy Act."
                  />
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    9.2 &nbsp;&nbsp;&nbsp;&nbsp;<b>Disclosure for analytics</b>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="We may use Google Analytics and other third-party analytics software to track your usage
of our Services. Google Analytics is a web analysis service provided by Google. Google
utilises the data collected to track and examine the use of our Services, to prepare reports
on its activities and share them with other Google services. "
                  />
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    Google may use the data collected to contextualise and
                    personalise the ads of its own advertising network. Personal
                    information collected by Google includes cookies and usage
                    data, which is processed in the United States of America
                    (subject to changes in Google’s data processing policies).
                    You can find Google's privacy policy{" "}
                    <a href="#" style={{ color: "orange" }}>
                      on their website
                    </a>
                    .
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    9.3 &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Consent to overseas disclosure</b>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Other than as contemplated in this Policy, we will only disclose your personal information
to an overseas recipient if:"
                  />
                </ListItem>
                <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="you consent to the transfer; or" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="the disclosure of the information is required, authorised or permitted by or under
an Australian law, other applicable law or a court/tribunal order."
                    />
                  </ListItem>
                </List>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Third Parties" />
                </CustomListItem>
                <ListItem>
                  <ListItemText
                    primary="Our Services may contain links to other sites that are not operated by us. If you click on a
third party link, you will be directed to that third party's site. We strongly advise you to
review the privacy policy of every site which you visit. "
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="We confirm that we have no control over, and assume no responsibility for, the content,
privacy policies, or practices of any third party sites, products or services whatsoever."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="You should be aware that your ability to opt-out of a third party tool or platform will depend
on the conditions governing your agreement with that third party."
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Competitions" />
                </CustomListItem>
                <ListItem>
                  <ListItemText
                    primary="Generally, all competitions run by us are hosted on our servers and are conducted, with
respect to the collection and use of personal information, in accordance with this Policy."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Competitions run by third parties, such as competitions run by our affiliates, commercial
partners, or sponsors, will be hosted on their own servers and the collection of personal
information will be subject to the privacy policies of the relevant third parties. We strongly
recommend that, before signing up to any third party competition, you carefully review the
privacy policy of the relevant third party."
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Direct Marketing" />
                </CustomListItem>
                <ListItem>
                  <ListItemText
                    primary="We may use your personal information to notify you of services that we are able to offer
you from time to time. You can opt our of receiving any marketing communications from
us by following the opt out instructions in those communications."
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Accessing and Managing Your Personal Information" />
                </CustomListItem>
                <ListItem>
                  <Typography variant="body1">
                    <b>Right to Access </b>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="You have a right, at all times, to obtain from confirmation from us as to whether or not
your personal information is being processed by us, where such processing is occurring,
and for what purpose."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText primary="We will provide you with access to your personal information held by us, unless:" />
                </ListItem>
                <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="giving access would be unlawful; or" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="denying access is required under any applicable law." />
                  </ListItem>
                </List>
                <ListItem>
                  <Typography variant="body1">
                    If you wish to access your personal information, please
                    contact us at{" "}
                    <a
                      href="mailto:guru@medconnectai.com"
                      style={{ color: "orange" }}
                    >
                      guru@medconnectai.com.
                    </a>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    <b>Amendment of Personal Information</b>
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    You can contact us to update or correct your personal
                    information at{" "}
                    <a
                      href="mailto:guru@medconnectai.com"
                      style={{ color: "orange" }}
                    >
                      guru@medconnectai.com.
                    </a>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Depending upon the personal information you seek to access, you may be asked:" />
                </ListItem>
                <List sx={{ listStyle: "lower-alpha", pl: 4 }}>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText primary="to put your request in writing with evidence to verify your identity; and/or" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item" }}>
                    <ListItemText
                      primary="to provide further details to assist us in responding to your request in a timely
manner."
                    />
                  </ListItem>
                </List>
                <ListItem>
                  <ListItemText
                    primary="We will respond to a request for access to personal information within a reasonable period
after the request is made (or such other period required by law)."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="If we elect not to correct your information, we will within a reasonable time (or such other
period required by law) notify you of the reason for our refusal, the mechanisms available
for you to complain about our refusal and such other matters required by the applicable
Privacy Laws."
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="International Users" />
                </CustomListItem>
                <ListItem>
                  <ListItemText
                    primary="If you are located outside Australia and choose to provide your personal information to us,
your personal information will be transferred to Australia or our overseas outsourced
information technology service providers and processed in accordance with this Policy."
                  />
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    If you are located in the European Union at the time, you
                    access our Services, or access or use our products or
                    services, the General Data Protection Regulation 2016/679
                    applies to our relationship with you. The Regulation in its
                    entirety may be accessed in your language at the{" "}
                    <a href="#" style={{ color: "orange" }}>
                      European Union Law Website
                    </a>
                    .
                  </Typography>
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Complaints" />
                </CustomListItem>
                <ListItem>
                  <ListItemText primary="We strive to ensure our compliance with this Policy and to regularly review our practices under it." />
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    If at any time you have a complaint against us regarding our
                    Policy, including a breach of the Privacy Laws, we invite
                    you to contact us at{" "}
                    <a
                      href="mailto:guru@medconnectai.com"
                      style={{ color: "orange" }}
                    >
                      guru@medconnectai.com
                    </a>
                    . All complaints made will be dealt with in confidence. We
                    endeavour to respond within 30 days of receipt of a
                    complaint with a resolution or proposed resolution of the
                    issue raised.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant="body1">
                    Please note that you may also make a complaint to the Office
                    of the Australian Information Commissioner (OAIC) about the
                    handling of your personal information. Information on making
                    a privacy complaint can be found on the{" "}
                    <a href="#" style={{ color: "orange" }}>
                      OAIC Website
                    </a>
                    .
                  </Typography>
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Variations" />
                </CustomListItem>
                <ListItem>
                  <ListItemText
                    primary="We reserve the right to vary this Policy from time to time without further notice to you. Any
variations made will be updated on our Services. We will not reduce your rights under this
Policy without using our best endeavours to first provide you with notice. It is your
responsibility to check our Policy every now and again to ensure that you are aware of any
changes made to it. "
                  />
                </ListItem>
                <CustomListItem sx={{ display: "list-item" }}>
                  <CustomListItemText primary="Further Information" />
                </CustomListItem>
                <ListItem>
                  <Typography variant="body1">
                    For any further information about this Policy please contact
                    us at{" "}
                    <a
                      href="mailto:guru@medconnectai.com"
                      style={{ color: "orange" }}
                    >
                      guru@medconnectai.com
                    </a>
                    .
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    );
  };
  
  export default PrivacyPolicy;
  