import { combineReducers } from "redux";
import authReducer from "./auth/reducer";

import sideBarReducers from "./sideBarIndex/reducer";
import snackbarReducers from "./snackbar/reducer";
import invoiceReducer from "./inoiceEdit/reducer"

const rootReducer = combineReducers({
  auth: authReducer,
  sideBarReducer: sideBarReducers,
  snackbarReducer: snackbarReducers,
  invoicePage: invoiceReducer,
});

 export type AuthState = ReturnType<typeof rootReducer>;

export default rootReducer;
