import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "../components/DashboardCard";
import DashBoardBarChart from "../components/DashBarChat";
import DashBoardTable from "../components/DashBoardTable";
import PieChart from "../components/PieChart";
import { CHANGE_INDEX } from "../store/sideBarIndex/actionTypes";
import common from "../config.json";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";

interface SummaryData {
  totalInvoice: number;
  totalAmount: number;
  pendingInvoice: number;
  pendingAmount: number;
  paidInvoice: number;
  paidAmount: number;
  draftInvoice: number;
  paidPercentage: number;
  pendingPercentage: number;
  draftPercentage: number;
  cancelInvoicePercentage: number;
}

const DashboardView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser:any = useAuthUser();
  const token = authUser()?.token;
  const [summary, setSummary] = useState<SummaryData | null>(null);
 

  const data = [
    {
      label: "Pending",
      value: summary?.pendingPercentage ? summary.pendingPercentage : "",
      color: "#67b0f0",
    },
    {
      label: "Paid",
      value: summary?.paidPercentage ? summary.paidPercentage : "",
      color: "#21d57b",
    },
    {
      label: "Cancel",
      value: summary?.cancelInvoicePercentage
        ? summary.cancelInvoicePercentage
        : "",
      color: "#f57f2ecc",
    },
    {
      label: "Draft",
      value: summary?.draftPercentage ? summary.draftPercentage : "",
      color: "#f5d649",
    },
  ];

   const formatDate = (date: Date): string => {
     const year = date.getFullYear();
     const month = String(date.getMonth() + 1).padStart(2, "0");
     const day = String(date.getDate()).padStart(2, "0");
     return `${year}-${month}-${day}`;
   };

   // Function to get the date 30 days ago
   const getPastDate = (): string => {
     const currentDate = new Date();
     const pastDate = new Date(currentDate);
     pastDate.setDate(pastDate.getDate() - 30); // Subtract 30 days
     return formatDate(pastDate);
   };

   const getCurrentDate = (): string => {
     const currentDate = new Date();
     const year = currentDate.getFullYear();
     let month = currentDate.getMonth() + 1;
     let day = currentDate.getDate();
     const monthString = month < 10 ? `0${month}` : `${month}`;
     const dayString = day < 10 ? `0${day}` : `${day}`;

     return `${year}-${monthString}-${dayString}`;
   };
  const [date, setDate] = useState({
    startDate: getPastDate(),
    endDate: getCurrentDate(),
  });


  useEffect(() => {
    console.log(date);
    getSummary();
  }, [date.startDate, date.endDate]);


  const getSummary = async() => {
    try{
      const url = common.BASE_URL+common.DASHBOARD_API+"/invoiceSummary";
      const response:any = await axios.get(url, {
        params: {
          startDate: date.startDate,
          endDate: date.endDate,
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setSummary(response.data.summary);
    }catch(error:any){
      console.log(error);
    }
  }

  const handleDateChange = (fieldName: string, value: string) => {
    setDate((prevDate) => ({
      ...prevDate,
      [fieldName]: value,
    }));
  };

  const viewInvoice = () => {
    navigate("/invoices");
    dispatch({ type: CHANGE_INDEX, value: 7 });
  };

  const createInvoice = () => {
    dispatch({ type: CHANGE_INDEX, value: 7 });
    navigate("/invoice");
  };

   

  return (
    <>
      <Grid
        container
        justifyContent="flex-end" // Align items to the end (right side)
        alignItems="flex-end"
        maxWidth="lg"
        rowSpacing={2}
        columnSpacing={3}
        item
      >
        <Grid item>
          <TextField
            id="createdDate"
            type="date"
            fullWidth
            variant="standard"
            label="start date"
            size="small"
            value={date.startDate}
            onChange={(e) => handleDateChange("startDate", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="dueDate"
            type="date"
            label="end date"
            fullWidth
            size="small"
            variant="standard"
            value={date.endDate}
            onChange={(e) => handleDateChange("endDate", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        maxWidth="lg"
        rowSpacing={2}
        columnSpacing={3}
        sx={{ m: "0" }}
      >
        <Grid item xs={10} sm={6} md={3}>
          <Card
            heading={"Total Invoice"}
            description={summary?.totalInvoice || 0}
            buttonName1={"view"}
            buttonName2={"+ invoices"}
            func1={createInvoice}
            func2={viewInvoice}
          />
        </Grid>
        <Grid item xs={10} sm={6} md={3}>
          <Card
            heading={"Pending Invoice"}
            description={summary?.pendingInvoice || 0}
            buttonName1={"view"}
            buttonName2={"+ invoices"}
            func1={createInvoice}
            func2={viewInvoice}
          />
        </Grid>
        <Grid item xs={10} sm={6} md={3}>
          <Card
            heading={"Paid Invoice"}
            description={summary?.paidInvoice || 0}
            buttonName1={"view"}
            buttonName2={"+ invoice"}
            func1={createInvoice}
            func2={viewInvoice}
          />
        </Grid>
        <Grid item xs={10} sm={6} md={3}>
          <Card
            heading={"Total Amount"}
            description={summary?.totalAmount || 0}
            buttonName1={"view"}
            buttonName2={"+ invoices"}
            func1={createInvoice}
            func2={viewInvoice}
          />
        </Grid> 
        <Grid item xs={10} sm={12} md={5}>
          <PieChart data={data} />
        </Grid>
        <Grid item xs={10} sm={12} md={7}>
          <DashBoardBarChart />
        </Grid>
        <Grid item xs={12} sm={11} md={12}>
          <DashBoardTable />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardView;
