
import {SET_STATUS } from "./actionTypes";
import { Actions, AppState } from "./types";

const initialState: AppState = {
  status: {
    message: "",
    type: "info",
    open: false,
  },
};

 const SnackbarReducers = (state = initialState, action: Actions) => {
  switch (action.type) {
    case SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};

export default SnackbarReducers;
