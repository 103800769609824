import { useContext, useState } from "react";

// material ui
import {
  Button,
  Container,
  FormHelperText,
  Grid,
 
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// third party libraries
import { Formik } from "formik";
import * as Yup from "yup";
import "../../Styles/PagesStyle/index.css";

// project import
import SnackBar from "../../components/Snackbar";
import { ProductContext } from "./ProductPage";
import common from '../../config.json';
import axios from "axios";
import image4 from "../../images/Avatar4.png";
import { useAuthUser } from "react-auth-kit";
import { formatInput, formatToUpper } from "../ValidationPage";

const EditPage = () => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const { setPage, test, user } = useContext(ProductContext);
  const authUser: any = useAuthUser();
  const token = authUser()?.token;
  
  const {
    productCode,
    name,
    price,
    brand,
    description,
    category,
    hsnCode,
  } = user;

 

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen(false);
   setPage("");
  };

  return (
    <Formik
      initialValues={{
        productCode: productCode || "",
        name: name || "",
        price: price || "",
        brand: brand || "",
        category: category || "",
        description: description || "",
        hsnCode: hsnCode || "",
      }}
      validationSchema={Yup.object().shape({
        productCode: Yup.string().required("First Name is required"),
        name: Yup.string().required("Name is required"),
        price: Yup.string().required("Price is required").min(1),
        brand: Yup.string(),
        category: Yup.string(),
        description: Yup.string(),
        hsnCode: Yup.string().required("HSN code is required"),
      })}
      onSubmit={async (values) => {
        console.log(values);
        const data = { ...values };
        let baseUrl = common.BASE_URL + common.PRODUCT_API + "/edit";

        console.log(data);
        try {
          const response = await axios
            .put(baseUrl, data, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then(function (response) {
              if (response.data) {
                setOpen(true);
                setType("success");
                setMessage("You have successfully updated records.");
              }
            });
          console.log(response);
        } catch (error: any) {
          setOpen(true);
          setType("error");
          setMessage(error.response.data.message || error.statusText);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Container maxWidth={false} disableGutters={false}>
            <Grid container spacing={0} maxWidth="850px">
              <Paper
                elevation={0}
                className="page-container-paper"
                sx={{
                  borderRadius: "16px",
                }}
              >
                <Typography variant="h5" sx={{ pb: "24px" }}>
                  Product Details
                </Typography>

                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      display="flex"
                      alignItems="center"
                      gap="12px"
                    >
                      <img width="34px" height="34px" src={image4} alt="" />
                      <Typography variant="h6">
                        {productCode} {name}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="productCode"
                      label="Product Code"
                      fullWidth
                      value={values.productCode}
                      name="productCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.productCode && errors.productCode)}
                      disabled
                    />
                    {touched.productCode && errors.productCode && (
                      <FormHelperText error id="productCode-helper-text">
                        {errors.productCode}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="name"
                      label="Name"
                      fullWidth
                      value={formatInput(values.name)}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="name-helper-text">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="price"
                      label="Price"
                      fullWidth
                      value={values.price}
                      name="price"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.price && errors.price)}
                    />
                    {touched.price && errors.price && (
                      <FormHelperText error id="price-helper-text">
                        {errors.price}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="brand"
                      label="Brand"
                      inputProps={{ maxLength: 10 }}
                      fullWidth
                      value={formatInput(values.brand)}
                      name="brand"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.brand && errors.brand)}
                    />
                    {touched.brand && errors.brand && (
                      <FormHelperText error id="brand-helper-text">
                        {errors.brand}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="category"
                      label="Category"
                      fullWidth
                      value={formatInput(values.category)}
                      name="category"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.category && errors.category)}
                    />
                    {touched.category && errors.category && (
                      <FormHelperText error id="category-helper-text">
                        {errors.category}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="hsnCode"
                      label="HSN Code"
                      fullWidth
                      value={formatToUpper(values.hsnCode)}
                      name="hsnCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.hsnCode && errors.hsnCode)}
                    />
                    {touched.hsnCode && errors.hsnCode && (
                      <FormHelperText error id="hsnCode-helper-text">
                        {errors.hsnCode}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="description"
                      label="Description"
                      fullWidth
                      multiline
                      value={formatInput(values.description)}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.description && errors.description)}
                    />
                    {touched.description && errors.description && (
                      <FormHelperText error id="description-helper-text">
                        {errors.description}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      className="custom-btn"
                      type="submit"
                      size="large"
                      variant="contained"
                      sx={{ mr: "12px" }}
                    >
                      Update
                    </Button>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        test === 1 ? setPage("profile") : setPage("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Container>
          {open ? (
            <SnackBar onClose={handleClose} data2={type} data3={message} />
          ) : (
            ""
          )}
        </form>
      )}
    </Formik>
  );
};

export default EditPage;
