import {
  SET_LOGIN_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SET_LOADING,
  LOGIN_FAILURE,
  RESET_AUTH_DATA,
} from "./actionTypes";

import { AuthActions, AuthState } from "./types";

const initialState: AuthState = {
  pending: false,
  error: null,
  loading: true,
  accessToken: "",
  refreshToken: "",
  expiresIn: "",
  id: "",
};

const reducers = (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        pending: false,
        accessToken: "",
        error: action.payload.error,
        id: "",
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        pending: false,
        accessToken: "",
        error: null,
        id: action.payload.id,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case SET_LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expiresIn: action.payload.expiresIn,
        error: null,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        accessToken: "",
        refreshToken:"",
        error: action.payload.error,
        id: "",
      };
    case RESET_AUTH_DATA:
      return {
        ...state,
        accessToken: "",
        refreshToken: "",
        expiresIn: "",
        error: null,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;