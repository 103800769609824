import { useState, useEffect } from "react";

// material ui
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

// third party libraries
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import "../../Styles/UserformStyle.css";
import common from "../../config.json";
import SnackBar from "../../components/Snackbar";

// project import
import { APP_LOGO } from "../../constant";
import PasswordStrengthChecker from "../../components/PasswordStrengthChecker";
import { useLocation, useNavigate } from "react-router-dom";

const NewPasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const[success ,setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
   const [open1, setOpen1] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const email:string = location.state?.email;

  useEffect(()=>{
   if(!email){
    navigate("/forgetpassword");
   }
  },[])


  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }

    setOpen(false);
  };
   const handleFocus = () => {
     setOpen1(true);
   };
   const handleBlurOut = () => {
     setOpen1(false);
   };
   
   const handleLogin = ()=>{
      navigate("/login")
   }
  
  return (
    <Formik
      initialValues={{
        password: "",
        matchingPassword: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Password is required"),
        matchingPassword: Yup.string()
          .required("Confirm Password is required")
          .oneOf([Yup.ref("password"), null], "Passwords don't match."),
      })}
      onSubmit={async (values) => {
        console.log(values);
        let baseUrl = common.BASE_URL + common.USER_API_URL + "/newpassword";
        try {
          await axios
            .post(
              baseUrl,
              {
                password: values.password,
                matchingPassword: values.matchingPassword,
                email: location.state.email, // "8a8ae990-2658-49c9-8af0-63774cb25403"
              },
              {
                headers: {
                  accept: "application/json",
                },
              }
            )
            .then(function (response) {
              if (response.data.success) {
                setOpen(true);
                setType("success");
                setMessage("You have successfully changed your password");
                setSuccess(true);
                navigate("/login");
              }
            });
        } catch (error: any) {
          setOpen(true);
          setType("error");
          setMessage(error.response.data.message || error.statusText);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values,
      }) => (
        <Container
          className="bg-image"
          maxWidth={false}
          sx={{ height: "100vh" }}
        >
          <Box className="resetCode-form">
            <div>
              <img className="logo-image" src={APP_LOGO} alt="logo"></img>
            </div>
            <div className="top-text1">
              {success ? (
                <>
                  <Typography variant="h5" sx={{ pb: "16px" }}>
                    Successful!
                  </Typography>
                  <Typography variant="subtitle1" color="rgba(0, 0, 0, 0.6)">
                    Your password has been changed. You may now sign in with
                    your new password.
                  </Typography>
                </>
              ) : (
                <Typography variant="h5">Reset Password</Typography>
              )}
            </div>
            <form
              className="form-input-box"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {success ? (
                <>
                  <Button
                    className="custom-btn"
                    variant="contained"
                    sx={{
                      fontSize: "15px",
                      width: "100%",
                      height: "42px",
                      marginTop: "16px",
                    }}
                    onClick={handleLogin}
                  >
                    Sign in
                  </Button>
                </>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PasswordStrengthChecker
                      data2={errors}
                      data3={touched}
                      data={open1}
                      onBlur={handleBlurOut}
                      onFocus={handleFocus}
                      value={values.password}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="matching-password"
                      name="matchingPassword"
                      label="Confirm Password"
                      fullWidth
                      value={values.matchingPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.matchingPassword && errors.matchingPassword && (
                      <FormHelperText error id="matching-password-helper-text">
                        {errors.matchingPassword}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className="custom-btn"
                      variant="contained"
                      sx={{
                        fontSize: "15px",
                        width: "100%",
                        height: "42px",
                        marginTop: "16px",
                      }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              )}
            </form>
          </Box>
          {open ? (
            <SnackBar onClose={handleClose} data2={type} data3={message} />
          ) : (
            ""
          )}
        </Container>
      )}
    </Formik>
  );
};

export default NewPasswordForm;
