import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { PDFViewer, StyleSheet } from "@react-pdf/renderer";
import MyDocument from "../Pages/Configurations/LayoutSetUp/Template1";
import common from "../config.json";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import selectStyles from "../Pages/Configurations/LayoutSetUp/StyleFunction";

interface PopupModalProps {
  open: boolean;
  data: any; // Assuming data is of type string, adjust as needed
  handleClose: () => void;
}

const PopupModal: React.FC<PopupModalProps> = ({ open, data, handleClose }) => {
  const authUser: any = useAuthUser();
  const token = authUser()?.token;
   const [details, setDetails] = useState({
     items: [
       {
         product: "",
         pname: "",
         hsn: "",
         quantity: "",
         price: "",
         tax: 18,
         amount: "",
       },
     ],
     companyId: "",
     customerId: "",
     createdDate: "",
     dueDate: "",
     paymentType: "",
     invoiceNumber: "",
     totalAmount: "",
     status: "Draft",
     sgst: "",
     cgst: "",
     template_no: "",
     taxedTotalAmount: "",
     companyDetails: {},
     billingDetails: {},
   });
  const [styles, setStyles] = useState({});

  useEffect(() => {
    getTemplateData();
    if (data.invoiceCode) {
      getInvoiceDetails();
    }
    getCompanyData();
    getCustomerData(data.customerId);
   
    
  }, [data]);

  const getTemplateData = async () => {
    let baseUrl = common.BASE_URL + common.TEMPLATE_API;

    try {
      const response = await axios.get(baseUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.success === true) {
        const singleTemplate = response.data.data;
        const apiResponseData = {
          layoutFormat: singleTemplate.layout_type,
          backgroundColor: singleTemplate.bg_color,
          fontColor: singleTemplate.font_color,
          templateType: singleTemplate.template_number,
        };
        const selectedStyles = await selectStyles(apiResponseData);
        const styles = StyleSheet.create(selectedStyles);
        setStyles({
          ...styles,
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getInvoiceDetails = async () => {
    const url = `${common.BASE_URL}${common.INVOICE_API}/${data.invoiceCode}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.success) {
        const invoiceDetails = response.data.data;
      
      // Fetch product details for each item
      const updatedItemsPromises = invoiceDetails.items.map(async (item: any) => {
        try {
          // Make an API call to fetch product details based on the product code
          const productResponse = await axios.get(`${common.BASE_URL}${common.PRODUCT_API}/${item.product_id}`, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          });
          if (productResponse.data.success) {
            const productData = productResponse.data.data;
            // Update the item with product name
            return {
              ...item,
              product: productData.product_name, // Assuming 'product_name' is the name of the product
            };
          }
        } catch (error) {
          console.error('Error fetching product details:', error);
        }
      });
      
      // Wait for all promises to resolve
      const updatedItems = await Promise.all(updatedItemsPromises);
        
        setDetails((prevData) => ({
          ...prevData,
          items: invoiceDetails.items.map((item: any) => ({
            product: item.product_id,
            pname:item.p_name,
            hsn: item.hsn,
            quantity: item.quantity,
            price: item.unit_price,
            tax: item.tax_rate,
            amount: item.amount,
          })),
        //   items:updatedItems,
          customerName:invoiceDetails.customerName,
          createdDate: invoiceDetails.created_date,
          dueDate: invoiceDetails.due_date,
          paymentType: invoiceDetails.payment_type,
          invoiceNumber: invoiceDetails.invoice_number,
          totalAmount: invoiceDetails.untax_amount,
          taxedTotalAmount:invoiceDetails.total_amount,
          sgst: invoiceDetails.sgst_amount,
          cgst: invoiceDetails.cgst_amount,
          template_no: invoiceDetails.template_no,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getCustomerData = async (customerId: any) => {
    try {
      let baseUrl = common.BASE_URL + common.CUSTOMERs_API + "/list";
      const response = await axios.get(baseUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const customer = response.data.data.find(
        (customer: any) => customer.id === customerId
      );
      if (customer) {
        const billingDetails: any = {
          name: customer.customer_name,
          address: `${customer.s_address}, ${customer.s_city}, ${customer.s_state}`,
          country: `${customer.s_country},- ${customer.s_postalcode}`,
          gstNo: customer.gst_number,
        };
         setDetails((prevData) => ({
           ...prevData,
           billingDetails: {
             ...billingDetails,
           },
         }));
      } 
    } catch (error) {
      console.log("Error fetching customer data:", error);
    }
  };

  const getCompanyData = async () => {
    let baseUrl = common.BASE_URL + common.COMPANY_DATA_API;

    try {
      const response = await axios.get(baseUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.success === true) {
        const companyData = response.data.data;

        const companyDetails: any = {
          id: companyData.id,
          logo: companyData.logo_image,
          name: companyData.name,
          address: `${companyData.address} ,${companyData.city} ,${companyData.state}`,
          country: `${companyData.country}-${companyData.postalcode}`,
          gstNo: companyData.gst_no,
          contact: `${companyData.phone} | ${companyData.email}`,
        };
         setDetails((prevData) => ({
           ...prevData,
           companyDetails: {
             ...companyDetails,
           },
         }));
      } 
    } catch (error: any) {
      console.log(error);
    }
  };

 

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invoice | {data.invoiceCode} </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PDFViewer width="500" height="400" showToolbar={false}>
                <MyDocument invoiceData={details} styles={styles} />
              </PDFViewer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupModal;
