import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import common from "../config.json";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import SnackBar from "./Snackbar";

interface PopupModalProps {
  open: boolean;
  data: any; // Assuming data is of type string, adjust as needed
  handleClose: () => void;
}

const ShareModel: React.FC<PopupModalProps> = ({ open, data, handleClose }) => {
  const authUser: any = useAuthUser();
  const token = authUser()?.token;
   const [open2, setOpen2] = useState(false);
   const [type, setType] = useState("success");
     const [phoneNumber, setPhoneNumber] = useState('');
   const [message, setMessage] = useState("");


 const handleClose2 = (
   event?: React.SyntheticEvent | Event,
   reason?: string
 ) => {
   if (reason === "clickAway") {
     return;
   }
   setOpen2(false);
 };

 useEffect(() => {
   getCustomerData(data.customerId);
 }, [data.customerId]);

  const getCustomerData = async (customerId: any) => {
    try {
      let baseUrl = common.BASE_URL + common.CUSTOMERs_API + "/list";
      const response = await axios.get(baseUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const customer = response.data.data.find(
        (customer: any) => customer.id === customerId
      );
      console.log("ccc",customer.phone_number);
      if (customer.phone_number) {
        setPhoneNumber(customer.phone_number);
      } else {
        setPhoneNumber("0");
      }
    } catch (error) {
      console.log("Error fetching customer data:", error);
    }
  };

 
  const handleSendMessage = (invoiceData:any) => {
    const { invoiceCode, customerName, amount, dueDate, status } = invoiceData;
    const phoneId = `+91${phoneNumber}`;
    const message = encodeURIComponent(`Invoice Details:
    Invoice Code: ${invoiceCode}
    Customer Name: ${customerName}
    Amount: ${amount}
    Due Date: ${dueDate}
    Status: ${status}
  `);
    const url = `https://wa.me/${phoneId}?text=${message}`;
    window.open(url, "_blank");
  };


   const handleSendMail = async (number: any) => {
     try {
       const baseUrl = `${common.BASE_URL}${common.INVOICE_API}/mailInvoice/${number}`;
       const response = await axios.get(baseUrl, {
         headers: {
           Authorization: "Bearer " + token,
         },
       });
       if (response.data.success) {
         setOpen2(true);
         setType("success");
         setMessage("Mail Send successfully.");
       }
     } catch (error: any) {
       setOpen2(true);
       setType("error");
       setMessage(
         error.response?.data?.message ||
           error.message ||
           "An error occurred while send invoice mail."
       );
     }
   };
 

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invoice | {data.invoiceCode} </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                startIcon={
                  <EmailIcon style={{ fontSize: 50, color: "#007bff" }} />
                }
                className="custom-btn2"
                sx={{
                  ":hover": {
                    background: "none",
                  },
                }}
                onClick={() => handleSendMail(data.invoiceCode)}
                fullWidth
              >
                Send Email
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                startIcon={
                  <WhatsAppIcon style={{ fontSize: 50, color: "#25D366" }} />
                }
                className="custom-btn2"
                sx={{
                  ":hover": {
                    background: "none",
                  },
                }}
                onClick={() => handleSendMessage(data)}
                fullWidth
              >
                Send WhatsApp Message
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {open2 ? (
        <SnackBar onClose={handleClose2} data2={type} data3={message} />
      ) : (
        ""
      )}
    </>
  );
};

export default ShareModel;
