import { createContext, useEffect, useState } from "react";

// material ui
import { Grid } from "@mui/material";

// project import
import {
  AddProduct,
  ProductEditPage,
  ProductView,
  ProductTable,
} from "./index";
import { Data } from "./ProductTable";
import image4 from "../../images/Avatar4.png";
import { useLocation } from "react-router-dom";


interface productProps {
  page: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  test: number;
  setTest: React.Dispatch<React.SetStateAction<number>>;
  addProduct: boolean;
  setAddProduct: React.Dispatch<React.SetStateAction<boolean>>;
  user: Data;
  setUser: React.Dispatch<React.SetStateAction<Data>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
}

export const ProductContext = createContext<productProps>({
  page: "",
  setPage: () => {},
  test: 0,
  setTest: () => {},
  addProduct: false,
  setAddProduct: () => {},
  user: {
    id: 0,
    productCode: "",
    name: "",
    hsnCode:"",
    category:"",
    description: "",
    brand:"",
    price: "",
    status: "false",
  },
  setUser: () => {},
  rowsPerPage: 5,
  setRowsPerPage: () => {},
});

// ==================== ADMIN SETTINGS PAGE =================== //
const ProductPage = () => {
  const [page, setPage] = useState("");
  const [test, setTest] = useState(0);
  const location = useLocation()
  const [addProduct, setAddProduct] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  const [user, setUser] = useState<Data>({
    id: 0,
    productCode: "",
    name: "",
    category: "",
    description: "",
    hsnCode:"",
    price: "",
    brand:"",
    status: "false",
  });

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: "100%",
      }}
    >
      <Grid item xs={12}>
        <ProductContext.Provider
          value={{
            page,
            setPage,
            test,
            setTest,
            addProduct,
            setAddProduct,
            user,
            setUser,
            rowsPerPage,
            setRowsPerPage,
          }}
        >
          {addProduct ? (
            <AddProduct />
          ) : page === "edit" ? (
            <ProductEditPage />
          ) : page === "profile" ? (
            <ProductView />
          ) : (
            <ProductTable />
          )}
        </ProductContext.Provider>
      </Grid>
    </Grid>
  );
};

export default ProductPage;
