import hospitalLogo from "../src/images/logoipSum.png";
import InvoiceLogo from "../src/images/WMSOneLogoINVC.png";
import avtarLogo from "../src/images/Avatar1.png";
import errorLogo from "../src/images/error.jpg";
import BookingIcon from "../src/images/MC_Booking.png";
import DiagnosisIcon from "../src/images/MC_Diagnosis.png";
import HomeIcon from "../src/images/MC_Home.png";
import AccountIcon from "../src/images/MC_MyAccount.png";
import PatientIcon from "../src/images/MC_Patient.png";
import RegistrationIcon from "../src/images/MC_Registration.png";
import ReportsIcon from "../src/images/MC_Reports.png";
import SettingsIcon from "../src/images/MC_Settings.png";
import TriageIcon from "../src/images/MC _Triage.png";
import wmsOneIcon from "../src/images/WMSICON.png";
import allergiesImg from "../src/images/allergies-image.jpg";
import medicalHistoryImg from "../src/images/medical-history-image.jpg";
import previousVisitImg from "../src/images/previous-visit-image.jpg";





export const HOSP_LOGO = hospitalLogo;
export const APP_LOGO = InvoiceLogo;
export const WMS_ONE_ICON = wmsOneIcon;
export const AP = avtarLogo;
export const ERROR_LOGO = errorLogo;
export const BOOKING_ICON = BookingIcon;
export const HOME_ICON = HomeIcon;
export const DIAGNOSIS_ICON = DiagnosisIcon;
export const REGISTRATION_ICON = RegistrationIcon;
export const REPORTS_ICON = ReportsIcon;
export const TRIAGE_ICON = TriageIcon;
export const SETTINGS_ICON = SettingsIcon;
export const ACCOUNT_ICON = AccountIcon;
export const PATIENT_ICON = PatientIcon;

export const MHISTORY_CARD_IMAGE = medicalHistoryImg;
export const PRE_VISIT_CARD_IMAGE = previousVisitImg;
