import { CHANGE_INDEX } from "./actionTypes";

import { stepperActions } from "./types";

const initialState = { activeIndex: 0 };
 

const sideBarReducers = (
  state = initialState.activeIndex,
  action: stepperActions
) => {
  switch (action.type) {
    case CHANGE_INDEX:
      return (state = action.value);

    default:
      return state;
  }
};

export default sideBarReducers;