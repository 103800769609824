// actions/authActions.js

import axios from "axios";
import { Dispatch } from "redux";
import common from "../../config.json";
import {
  SET_LOGIN_SUCCESS,
  SET_LOADING,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  LOGIN_FAILURE,
} from "./actionTypes";

export type AuthAction = {
  type: string;
  payload?: any;
};

// Action creator for login
export const login = (data:any) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
    const { email, password } = data;
    const apiUrl  = common.BASE_URL + common.USER_API_URL +"/login";

    try {
      const response = await axios.post(apiUrl, { email, password });
      dispatch({
        type: SET_LOGIN_SUCCESS,
        payload: {
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          expiresIn: response.data.expiresIn,
        },
      });

    } catch (error:any) {
      dispatch({
        type: LOGIN_FAILURE,
        payload: { error: error.response.data.message },
      });
    } finally {
      dispatch({ type: SET_LOADING, payload: { loading: false } });
    }
  };
};

// Action creator for register
export const register = (userData: any) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: SET_LOADING, payload: { loading: true } });
 const apiUrl = common.BASE_URL + common.USER_API_URL+ "/signup";
    try {
      const response = await axios.post(apiUrl, userData);
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: { id: response.data.id },
      });
    } catch (error: any) {
      dispatch({
        type: SIGNUP_FAILURE,
        payload: { error: error.response.data.message },
      });
    } finally {
      dispatch({ type: SET_LOADING, payload: { loading: false } });
    }
  };
};
