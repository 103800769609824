import { createContext, useEffect, useState } from "react";

// material ui
import { Grid } from "@mui/material";

// project import
import {
  AddCustomer,
  CustomerEditPage,
  CustomerView,
  CustomerTable,
} from "./index";
import { Data } from "./CustomerTable";
import { useLocation } from "react-router-dom";


interface customerProps {
  page: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  test: number;
  setTest: React.Dispatch<React.SetStateAction<number>>;
  addCustomer: boolean;
  setAddCustomer: React.Dispatch<React.SetStateAction<boolean>>;
  user: Data;
  setUser: React.Dispatch<React.SetStateAction<Data>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
}

export const CustomerContext = createContext<customerProps>({
  page: "",
  setPage: () => {},
  test: 0,
  setTest: () => {},
  addCustomer: false,
  setAddCustomer: () => {},
  user: {
    id: 0,
    customerCode: "",
    name: "",
    phone: "",
    email: "",
    gstNo:"",
    address: "",
    country: "",
    state: "",
    pinCode: "",
    city: "",
    status: 1,
    address1:"",
  },
  setUser: () => {},
  rowsPerPage: 5,
  setRowsPerPage: () => {},
});

// ==================== ADMIN SETTINGS PAGE =================== //
const CustomerPage = () => {
  const [page, setPage] = useState("");
  const [test, setTest] = useState(0);
  const location = useLocation()
  const [addCustomer, setAddCustomer] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  

  useEffect(() => {
    if(location.pathname.split("/")[2] === "add_staff") {
      setAddCustomer(true)
    }
  },[])
  const [user, setUser] = useState<Data>({
    id: 0,
    customerCode: "",
    name: "",
    phone: "",
    email: "",
    gstNo: "",
    address: "",
    country: "",
    state: "",
    pinCode: "",
    city: "",
    status: 1,
    address1: "",
  });

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: "100%",
      }}
    >
      <Grid item xs={12}>
        <CustomerContext.Provider
          value={{
            page,
            setPage,
            test,
            setTest,
            addCustomer,
            setAddCustomer,
            user,
            setUser,
            rowsPerPage,
            setRowsPerPage,
          }}
        >
          {addCustomer ? (
            <AddCustomer />
          ) : page === "edit" ? (
            <CustomerEditPage />
          ) : page === "profile" ? (
            <CustomerView />
          ) : (
            <CustomerTable />
          )}
        </CustomerContext.Provider>
      </Grid>
    </Grid>
  );
};

export default CustomerPage;
