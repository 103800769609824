import * as React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import '../Styles/ComponentsStyle/index.css';

type alertProps ={
    message:string,
}

const AlertBar = ({message}:alertProps)=> {
  return (
    <Stack className="alertBar-container" spacing={2}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {message}
      </Alert>
    </Stack>
  );
}
export default AlertBar;
