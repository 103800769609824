import { useEffect, useState } from "react";

// material ui
import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
  MenuItem,
} from "@mui/material";

// third party libraries
import { Formik, Field } from "formik";
import * as Yup from "yup";

// project import
import SnackBar from "../../../components/Snackbar";
import { formatPhoneNumber } from "../../ValidationPage";
import axios from "axios";
import common from "../../../config.json";
import { useAuthUser } from "react-auth-kit";


interface FormDataValues {
  companyName: string;
  companyType:string;
  address: string;
  phone: string;
  email: string;
  city: string;
  country: string;
  state: string;
  pinCode: string;
  taxId: string;
  companyId: string;
  gstNo: string;
  website: string;
  currency: string;
  companyLogo: string;
  logo: File | null; // Adjust the type to File or null
}

// ===================== PATIENT MY DETAILS PAGE ===================== //
const MyAccountDetailsPage = () => {
  const authUser: any = useAuthUser();
 const token = authUser()?.token;
 const[id,setId] = useState(false)

  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const [data, setData] = useState<FormDataValues>({
    companyName: "",
    companyType:"",
    address: "",
    phone: "",
    email: "",
    city: "",
    country: "",
    state: "",
    pinCode: "",
    taxId: "",
    companyId: "",
   gstNo: "",
    website: "",
    currency: "",
    companyLogo: "",
    logo: null, 
  });

  const getCompanyData = async () => {
    let baseUrl = common.BASE_URL + common.COMPANY_DATA_API;

    try {
      const response = await axios.get(baseUrl, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.success === true) {
        const companyData = response.data.data;

        // Extract relevant fields from companyData
        const newData: FormDataValues = {
          companyName: companyData.name || "",
          companyType:companyData.companyType||"",
          address: companyData.address || "",
          phone: String(companyData.phone) || "",
          email: companyData.email || "",
          city: companyData.city || "",
          country: companyData.country || "",
          state: companyData.state || "",
          pinCode: String(companyData.postalcode) || "",
          taxId: companyData.tax_id || "",
          companyId: String(companyData.id) || "",
          gstNo: companyData.gst_no || "",
          website: companyData.website || "",
          currency: "", // You may need to fetch currency data separately
          companyLogo: companyData.logo_image || "", // Assuming this is the correct field for logo
          logo: null,
        };
        setId(true);
        setData(newData);
      }
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message || error.statusText);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  const addCompanyData = async (values: FormDataValues) => {
    let baseUrl = common.BASE_URL + common.COMPANY_DATA_API + "/add";

    try {
      const formData = new FormData();

      // Append other fields to formData
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Append companyLogo file to formData if it exists

      const response = await axios.post(baseUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.success === true) {
        setOpen(true);
        setType("success");
        setMessage("You have successfully added company details.");
      }
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message || error.statusText);
    }
  };

  const updateCompanyData = async (values: FormDataValues) => {
    let baseUrl = common.BASE_URL + common.COMPANY_DATA_API + "/edit";

    try {
      const formData = new FormData();

      // Append other fields to formData
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const response = await axios.put(baseUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.success === true) {
        setOpen(true);
        setType("success");
        setMessage("You have successfully updated company details.");
      }
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message || error.statusText);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...data }}
      validationSchema={Yup.object().shape({
        companyName: Yup.string().required("Company Name is required"),
        companyType: Yup.string().required("Company type is required"),
        address: Yup.string().required("Address is required"),
        phone: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .required("Phone number is required")
          .min(10, "Phone number must be 10 digits")
          .max(10, "Phone number must be 10 digits"),
        email: Yup.string()
          .email("Enter a valid email")
          .required("Email is required"),
        city: Yup.string().required("City is required"),
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
        pinCode: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .required("Pin code is required"),
        taxId: Yup.string().required("Tax ID is required"),
        gstNo: Yup.string(),
        website: Yup.string().url("Enter a valid URL"),
        currency: Yup.string(),
      })}
      onSubmit={async (values) => {
        console.log(values);
        id ? updateCompanyData(values) : addCompanyData(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Container maxWidth={false} disableGutters={false}>
            <Grid container spacing={0}>
              <Paper
                elevation={0}
                className="page-container-paper"
                sx={{
                  borderRadius: "16px",
                }}
              >
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Set your company data
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="company-name"
                        label="Company name"
                        fullWidth
                        value={values.companyName}
                        name="companyName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.companyName && errors.companyName
                        )}
                      />
                      {touched.companyName && errors.companyName && (
                        <FormHelperText error id="company-name-helper-text">
                          {errors.companyName}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="mobile-phone"
                        label="Mobile Phone"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                        value={formatPhoneNumber(values.phone)}
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.phone && errors.phone)}
                      />
                      {touched.phone && errors.phone && (
                        <FormHelperText error id="mobile-phone-helper-text">
                          {errors.phone}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="email"
                        label="Email"
                        fullWidth
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.email && errors.email)}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error id="email-helper-text">
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        required
                        id="address"
                        label="Address"
                        fullWidth
                        value={values.address}
                        name="address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.address && errors.address)}
                      />
                      {touched.address && errors.address && (
                        <FormHelperText error id="address-helper-text">
                          {errors.address}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="state"
                        label="State"
                        fullWidth
                        value={values.state}
                        name="state"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.state && errors.state)}
                      />
                      {touched.state && errors.state && (
                        <FormHelperText error id="state-helper-text">
                          {errors.state}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="city"
                        label="City"
                        fullWidth
                        value={values.city}
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.city && errors.city)}
                      />
                      {touched.city && errors.city && (
                        <FormHelperText error id="city-helper-text">
                          {errors.city}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="pinCode"
                        label="PinCode"
                        fullWidth
                        value={values.pinCode}
                        name="pinCode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.pinCode && errors.pinCode)}
                      />
                      {touched.pinCode && errors.pinCode && (
                        <FormHelperText error id="pinCode-helper-text">
                          {errors.pinCode}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="country"
                        label="Country"
                        fullWidth
                        value={values.country}
                        name="country"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.country && errors.country)}
                      />
                      {touched.country && errors.country && (
                        <FormHelperText error id="country-helper-text">
                          {errors.country}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="taxId"
                        label="Tax Id"
                        fullWidth
                        value={values.taxId}
                        name="taxId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.taxId && errors.taxId)}
                      />
                      {touched.taxId && errors.taxId && (
                        <FormHelperText error id="taxId-helper-text">
                          {errors.taxId}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="gstNo"
                        label="GST Number"
                        fullWidth
                        value={values.gstNo}
                        name="gstNo"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.gstNo && errors.gstNo)}
                      />
                      {touched.gstNo && errors.gstNo && (
                        <FormHelperText error id="gstNo-helper-text">
                          {errors.gstNo}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="website"
                        label="Website"
                        fullWidth
                        value={values.website}
                        name="website"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.website && errors.website)}
                      />
                      {touched.website && errors.website && (
                        <FormHelperText error id="website-helper-text">
                          {errors.website}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        select
                        id="company-type"
                        name="companyType"
                        label="company Type"
                        fullWidth
                        value={values.companyType}
                        onChange={handleChange}
                      >
                        <MenuItem value="restaurants">Restaurants</MenuItem>
                        <MenuItem value="others">Others</MenuItem>
                      </TextField>
                      {touched.companyType && errors.companyType && (
                        <FormHelperText error>
                          {errors.companyType}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Field
                        name="file"
                        type="file"
                        onChange={(event: any) => {
                          const files = event.currentTarget.files;
                          if (files && files.length > 0) {
                            const file = files[0];
                            const reader = new FileReader();
                            reader.onload = (e) => {
                              setFieldValue("logo", file);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />

                      <Typography variant="body2">
                        {values.companyLogo
                          ? "File selected"
                          : "No file selected"}
                      </Typography>
                      {values.companyLogo && (
                        <img
                          src={values.companyLogo}
                          alt="Company Logo"
                          style={{ maxWidth: "25%", marginTop: 10 }}
                        />
                      )}
                      {touched.companyLogo && errors.companyLogo && (
                        <FormHelperText error id="companyLogo-helper-text">
                          {errors.companyLogo}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        className="custom-btn"
                        type="submit"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                  {open ? (
                    <SnackBar
                      onClose={handleClose}
                      data2={type}
                      data3={message}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Container>
          {open ? (
            <SnackBar onClose={handleClose} data2={type} data3={message} />
          ) : (
            ""
          )}
        </form>
      )}
    </Formik>
  );
};

export default MyAccountDetailsPage;
