import "../../Styles/UserformStyle.css";
import { APP_LOGO } from "../../constant";
import React, { useEffect, useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthState } from "../../store/rootReducer";
import UserRegistrationForm from "./SignUpForm";

const Captcha = () => {
  const guid = useSelector((state: AuthState) => state.auth.id);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (success) {
      navigate("/confirmEmail");
    }
  }, [success, navigate]);

  let sitekey = "";
  const handleChange = async () => {
    setSuccess(true);
  };

  sitekey = "6Ldejq8mAAAAAEPUFLp7W8l5Ds5mn9V6mIbuYTob";
  // if (process.env.NODE_ENV !== "production") {
  //   sitekey = "6Ldejq8mAAAAAEPUFLp7W8l5Ds5mn9V6mIbuYTob";
  // } else {
  //   sitekey = "6Ldejq8mAAAAAEPUFLp7W8l5Ds5mn9V6mIbuYTob";
  // }

  return guid ? (
    <Container className="bg-image" maxWidth={false} sx={{ height: "100vh" }}>
      <Box className="captcha-form">
        <div>
          <img className="logo-image" src={APP_LOGO} alt="logo"></img>
        </div>
        <div className="top-text">
          <Typography variant="h5" sx={{ mb: "8px" }}>
            Captcha verification
          </Typography>
        </div>
        <div className="top-text">
          <Typography
            variant="subtitle1"
            sx={{ mt: "16px", color: "rgba(0, 0, 0, 0.6)" }}
          >
            Please confirm you are a human
          </Typography>
        </div>

        <div className="captcha">
          <ReCAPTCHA sitekey={sitekey} onChange={handleChange} />
        </div>
        <div className="captcha-bottom-text">
          <Typography variant="caption" display="block">
            By signing up, you agree and accept our{" "}
            <span>
              <Link
                to="/terms_and_conditions"
                style={{ color: "#1565C0", textDecoration: "none" }}
              >
                Terms and Conditions
              </Link>
            </span>{" "}
            and{" "}
            <span>
              <Link
                to="/privacy_policy"
                style={{ color: "#1565C0", textDecoration: "none" }}
              >
                Privacy Policy.
              </Link>
            </span>
          </Typography>
        </div>
      </Box>
    </Container>
  ) : (
    <UserRegistrationForm />
  );
};
export default Captcha;
