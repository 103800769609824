import React, { useEffect, useState } from "react";
import "../../Styles/UserformStyle.css";
import { APP_LOGO } from "../../constant";
import * as Yup from "yup";
import { Formik } from "formik";
import OtpInput from "react18-input-otp";
import {
  Container,
  Typography,
  Button,
  Box,
} from "@mui/material";

import { Link, useNavigate, } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { AuthState } from "../../store/rootReducer";
import common from "../../config.json";
import SnackBar from "../../components/Snackbar";

const validationSchema = Yup.object().shape({
  code: Yup.string().required("required").max(6),
});




interface IFormInput {
  code: string;
}

const ConfirmSignup = () => {
  const navigate  = useNavigate();
  const guid = useSelector((state: AuthState) => state.auth.id);
   const [open, setOpen] = useState(false);
   const [send, setSend] = useState(false);
   const [type, setType] = useState("success");
   const [message, setMessage] = useState("");
    const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [email, setEmail] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }

    setOpen(false);
  };

   const handleChanged = (enteredOtp: string) => {
     setOtp(enteredOtp);
   };

  useEffect(() => {
    setOpen(false);
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendMail = async () => {
    let baseUrl = '';
    //  if (process.env.NODE_ENV !== "production") {
    //   baseUrl =
    //     common.USER_API_URL + "/resendregistrationtoken/mail";
    //  }

    //  else{
    //   baseUrl = common.BASE_URL + common.USER_API_URL + "/resendregistrationtoken/mail";
      
    //  }
     
    try {
      const response = await axios.get(baseUrl, {
        params: { guid: guid },
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (response.data) {
        setOpen(true);
        setType("success");
        setMessage("Your code has been resent.");
        setSeconds(30);
        setSend(true);
        
      }
    } catch (error) {
      setOpen(true);
      setType("error");
      setMessage("Unable to send OTP.");
    }
  };

  // const handleVerifyOtp = async () => {
  //    let baseUrl2 = "";
  //    if (process.env.NODE_ENV !== "production") {
  //      baseUrl2 = common.USER_API_URL + "/confirmregistration";
  //    } else {
  //      baseUrl2 =
  //        common.BASE_URL + common.USER_API_URL + "/confirmregistration";
  //    }

  //   try {
  //     const response = await axios.get(baseUrl2, {
  //       params: { guid: guid, totp: otp },
  //       headers: {
  //         Accept: "*/*",
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     });
  //     if (response.data === "valid") {
  //           setOpen(true);
  //           setType("success");
  //           setMessage("Registered Email Verified Successfully");
  //           navigate("/login");
  //         }
  //         else{
  //            setOpen(true);
  //            setType("error");
  //            setMessage("Incorrect OTP,please try again.");
  //         }
  //       } catch (error: any) {
  //         setOpen(true);
  //         setType("error");
  //         setMessage(error.message);
  //       }
  //     }
  return (
    <Formik
      initialValues={{
        code: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (data: IFormInput) => {
        let baseUrl2 = "";
        // if (process.env.NODE_ENV !== "production") {
        //   baseUrl2 = common.USER_API_URL + "/confirmregistration";
        // } else {
        //   baseUrl2 =
        //     common.BASE_URL + common.USER_API_URL + "/confirmregistration";
        // }
        try {
          const response = await axios.get(baseUrl2, {
            params: { guid: guid, totp: data.code },
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          });
          if (response.data === "valid") {
            setOpen(true);
            setType("success");
            setMessage("Registered Email Verified Successfully");
             navigate("/login");
          }
        } catch (error: any) {
          setOpen(true);
          setType("error");
          setMessage(error.message);
        }
      }}
    >
      {({ handleSubmit }) => (
        <Container
          className="bg-image"
          maxWidth={false}
          sx={{ height: "100vh" }}
        >
          <Box className="resetCode-form">
            <div>
              <img className="logo-image" src={APP_LOGO} alt="logo"></img>
            </div>
            <div className="top-text1">
              <Typography variant="h5">2 Step Verification</Typography>
            </div>
            <form className="form-input-box" onSubmit={handleSubmit}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "rgba(0, 0, 0, 0.6)",
                  display: "inline-block",
                }}
              >
                "We have sent an email with a 6 digit code to your registered
                email address.Enter the code to verify your account."
              </Typography>
              <OtpInput
                value={otp}
                onChange={handleChanged}
                numInputs={6}
                separator={<span></span>}
                inputStyle={{
                  width: "50px",
                  height: "50px",
                  // fontSize: "24px",
                  margin: "3px",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#000000",
                  marginTop: "12px",
                }}
              />
              <div style={{ display: "flex" }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    mt: "12px",
                    color: "#1565C0",
                  }}
                >
                  {seconds > 0 && send ? (
                    "Your code has been resent"
                  ) : seconds > 0 ? (
                    <Typography color=" rgba(0, 0, 0, 0.38)">
                      Resend Code
                    </Typography>
                  ) : (
                    <Typography
                      onClick={resendMail}
                      sx={{ textDecoration: "underline" }}
                    >
                      Resend Code
                    </Typography>
                  )}
                </Typography>
                <span style={{ color: " rgba(0, 0, 0, 0.38)", margin: "16px" }}>
                  00:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </div>
              <div className="bottom-text">
                <Typography variant="caption" display="block">
                  By signing up, you agree and accept our{" "}
                  <span>
                    <Link
                      to="/terms_and_conditions"
                      style={{ color: "#1565C0", textDecoration: "none" }}
                    >
                      Terms and Conditions
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    <Link
                      to="/privacy_policy"
                      style={{ color: "#1565C0", textDecoration: "none" }}
                    >
                      Privacy Policy.
                    </Link>
                  </span>
                </Typography>
              </div>

              <Button
                className="custom-btn"
                variant="contained"
                sx={{
                  fontSize: "15px",
                  width: "100%",
                  height: "42px",
                }}
               // onClick={handleVerifyOtp}
              >
                Confirm and Signup
              </Button>
              {/* <div
                style={{
                  marginTop: "12px",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    p: 0,
                  }}
                  onClick={() => {setEmail(!email);
                  setSeconds(30);}}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      borderBottom: "1px solid #1565C0",
                    }}
                  >
                    Switch to {email ? "mobile" : "email"} authentication
                  </Typography>
                </Button>
              </div> */}
            </form>
          </Box>

          {open ? (
            <SnackBar onClose={handleClose} data2={type} data3={message} />
          ) : (
            ""
          )}
        </Container>
      )}
    </Formik>
  );
};

export default ConfirmSignup;
