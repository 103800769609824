import { MouseEvent, useState } from "react";
// material ui
import {
  AccountCircle,
  KeyboardArrowDown,
  MoreVert,
  Notifications,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import '../Styles/DashBoard.css';

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { 
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
} from "@mui/material";

// third party libraries
import { useAuthUser, useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { RESET_AUTH_DATA } from "../store/auth/actionTypes";

// project import
import LeftMenu from "./LeftMenu";
import { HOSP_LOGO } from "../constant";
import common from "../config.json";
import axios from "axios";
import SnackBar from "../components/Snackbar";
import { useDispatch } from "react-redux";

 interface propsValue {
  roleName:string;
}

//style for topBar-- ///
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: 250,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    height: "92px",
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    height: "92px",
    marginLeft: 65,
    width: `calc(100% - ${65}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": { background: "none" },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          width: "368px",
          "& .MuiInputLabel-root.Mui-focused": { color: "#595959" },
          "& .MuiOutlinedInput-root": {},

          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": { borderColor: "#000000" },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6F8FC",
          zIndex: "1201",
          // borderTop: "2px solid #1565C0",
           boxShadow: "2px 0px 10px rgba(0, 0, 0, 0)",
        },
      },
    },
  },
});


//-------end of style ------//

export default function NavbarDrawer() {
  const dispatch = useDispatch();
  const authUser: any = useAuthUser();
  const userName: any = authUser()?.userName;
  const signOut = useSignOut();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [open1, setOpen1] = useState(false);
  const [type, setType] = useState("error");
  const [message, setMessage] = useState("");

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen1(false);
  };

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
 
  const logout = async () => {
    await dispatch({type:RESET_AUTH_DATA});
    signOut();
    navigate("/login");
  };

  //   const out= async()=>{
  //     let baseUrl = "";
  //     if (process.env.NODE_ENV !== "production") {
  //       baseUrl = "/logout";
  //     } else {
  //       baseUrl = common.BASE_URL + "/logout";
  //     }
  //         try {
  //           await axios
  //             .post(
  //               baseUrl,

  //               {
  //                 headers: {
  //                   accept: "application/json",
  //                   Authorization: "Bearer " + token,
  //                 },
  //               }
  //             )
  //             .then(function (response) {
  //               if (response.status === 200) {
  //                 setOpen1(true);
  //                 setType("success");
  //                 setMessage("You have successfully logout");

  //               }
  //             });
  //         } catch (error: any) {
  //           setOpen1(true);
  //           setType("error");
  //           setMessage(error.response.data.message || error.response.statusText );

  //   }
  // }

  const handleMobileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMyAccount = () => {
    navigate("/myAccount");
    handleMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      sx={{
        marginTop: "54px",
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled onClick={handleMenuClose}>
        {userName}
      </MenuItem>
      <MenuItem onClick={handleMyAccount}>My Account</MenuItem>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge>
            <Notifications />
          </Badge>
        </IconButton>
        <p>notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>{userName ? userName : ""}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="absolute" open={open}>
        <Toolbar>
          {/* {role !== "PATIENT" && role !== "USER" ? <SearchBox /> : ""} */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex", padding: "18px" } }}>
            <IconButton className="Icon-button">
              <Badge>
                <Notifications sx={{ color: "black" }} />
              </Badge>
            </IconButton>
            <IconButton
              disableRipple
              sx={{ bg: "none" }}
              className="Icon-button"
              aria-controls={menuId}
              onClick={handleProfileMenuOpen}
              aria-haspopup="false"
            >
              <Typography variant="subtitle2" sx={{ color: "black" }}>
                {userName ? userName : ""}
              </Typography>
              {/* {false ? (
                <Typography variant="subtitle2" sx={{ color: "black" }}>
                  {userName ? userName : role}
                </Typography>
              ) : (
                // <Avatar src={AP} className="avatar" />
                <Typography variant="subtitle2" sx={{ color: "black" }}>
                  {userName ? userName : role}
                </Typography>
                // <Avatar src={AP} className="avatar" />
              )} */}

              <KeyboardArrowDown sx={{ color: "black" }} />
            </IconButton>

            <Box
              className="rightSide-logo"
              component="img"
              alt="hospital-logo"
              src={HOSP_LOGO}
              sx={{ maxWidth: "150px" }}
            />
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreVert sx={{ color: "#000000" }} />
            </IconButton>
          </Box>
          {renderMobileMenu}
          {renderMenu}
        </Toolbar>
        {open1 ? (
          <SnackBar onClose={handleClose} data2={type} data3={message} />
        ) : (
          ""
        )}
      </AppBar>

      <LeftMenu open={open} handleDrawerOpen={handleDrawerOpen} />
    </ThemeProvider>
  );
}
