
import { useEffect, useState } from "react";
// material ui
import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Container,
  Paper,
  RadioGroup,
  MenuItem,
  FormControlLabel,
  Radio,
} from "@mui/material";

// third party libraries
import { Formik } from "formik";
import * as Yup from "yup";
import { PDFViewer, StyleSheet} from "@react-pdf/renderer";
import selectStyles from './StyleFunction';
import common from '../../../config.json';
import MyDocument from "./Template1";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import SnackBar from "../../../components/Snackbar";

const LayoutPage =()=>{
   const authUser:any = useAuthUser();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
   const [check, setCheck] = useState(false);
   const token = authUser()?.token;

      const [data, setData] = useState({
        layoutFormat: "",
        backgroundColor: "",
        fontColor: "",
        templateType: "",
        fontType:"",
      });

      const selectedStyles = selectStyles(data);
      const styles =  StyleSheet.create(selectedStyles);

    const invoiceData = {
      invoiceNumber: "INV1345890",
      createdDate: "February 21, 2024",
      dueDate: "March 12,2024",
      companyDetails: {
        name: "Kanishk",
        address: "Sector 125 Noida, UP",
        country: "India-201303",
        gstNo: "GDJ67NU90HTU2024",
      },
      billingDetails: {
        name: "Sunny",
        address: "Sector 62,Noida",
        country: "India-201202",
        gstNo: "GFH76M8B32BOI767",
      },
      items: [
        {
          pname: "Product 1",
          hsn: "HSN123",
          quantity: "20",
          price: "500",
          tax: "18",
          amount: "10000",
        },
        {
          pname: "Product 2",
          hsn: "HSN456",
          quantity: "30",
          price: "400",
          tax: "18",
          amount: "12000",
        },
        {
          pname: "Product 3",
          hsn: "HSN789",
          quantity: "20",
          price: "300",
          tax: "18",
          amount: "6000",
        },
      ],
      totalAmount: "10000",
      sgst: "900",
      cgst: "900",
      taxedTotalAmount: "11800",
    };

     const getTemplate = async () => {
       let baseUrl = common.BASE_URL + common.TEMPLATE_API;

       try {
         const response = await axios
           .get(baseUrl, {
             headers: {
               Authorization: "Bearer " + token,
             },
           })
             if (response.data.success === true) { 
                const singleTemplate = response.data.data;
                setData({
                  layoutFormat: singleTemplate.layout_type,
                  backgroundColor: singleTemplate.bg_color,
                  fontColor: singleTemplate.font_color,
                  templateType: singleTemplate.template_number,
                  fontType: singleTemplate.font_type,
                });
                setCheck(true);
             }
       } catch (error: any) {
         setOpen(true);
         setType("error");
         setMessage(error.response.data.message || error.statusText);
       }
     };

    useEffect(()=>{
      getTemplate();

    },[])

     const handleChange = (e:any) => {
       const { name, value } = e.target;
       setData((prevData) => ({
         ...prevData,
         [name]: value,
       }));
     };



     const addTemplate = async(values:any) =>{
      let baseUrl = common.BASE_URL + common.TEMPLATE_API + "/add";
        
        try {
          const response = await axios
            .post(baseUrl, values, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
           
             if (response.data.success === true)  {
                setOpen(true);
                setType("success");
                setMessage("You have successfully added template.");
              }
        } catch (error: any) {
          setOpen(true);
          setType("error");
          setMessage(error.response.data.message || error.response.statusText);
        }
      }

       const updateTemplate = async (values: any) => {
         let baseUrl = common.BASE_URL + common.TEMPLATE_API + "/edit";

         try {
           const response = await axios.put(baseUrl, values, {
             headers: {
               Authorization: "Bearer " + token,
             },
           });

           if (response.data.success === true) {
             setOpen(true);
             setType("success");
             setMessage("You have successfully updated template.");
           }
         } catch (error: any) {
           setOpen(true);
           setType("error");
           setMessage(error.response.data.message || error.response.statusText);
         }
       };


        const handleClose = (
          event?: React.SyntheticEvent | Event,
          reason?: string
        ) => {
          if (reason === "clickAway") {
            return;
          }
          setOpen(false);
        };

return (
  <>
    <Formik
      enableReinitialize={true}
      initialValues={{ ...data }}
      validationSchema={Yup.object().shape({
        layoutFormat: Yup.string().required("layout format is required"),
        backgroundColor: Yup.string().required("background color is required"),
        templateType: Yup.string().required("template type is required"),
        fontColor: Yup.string().required("font color  is required"),
        fontType: Yup.string().required("font type  is required"),
      })}
      onSubmit={(values) => {
        console.log(values);
        check ? updateTemplate(values) : addTemplate(values);
      }}
    >
      {({ errors, handleSubmit, touched, values, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Container maxWidth={false} disableGutters={false}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={6}>
                {/* Left side: Form */}
                <Paper
                  elevation={0}
                  className="page-container-paper"
                  sx={{ borderRadius: "8px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Set invoice layout</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography variant="subtitle1">Layout Format</Typography>
                      <RadioGroup
                        aria-label="layoutFormat"
                        name="layoutFormat"
                        value={values.layoutFormat}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="light"
                          control={<Radio />}
                          label="Light"
                        />
                        <FormControlLabel
                          value="striped"
                          control={<Radio />}
                          label="Striped"
                        />
                        <FormControlLabel
                          value="bold"
                          control={<Radio />}
                          label="Bold"
                        />
                      </RadioGroup>
                      {touched.layoutFormat && errors.layoutFormat && (
                        <FormHelperText error>
                          {errors.layoutFormat}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        select
                        id="template-type"
                        name="templateType"
                        label="Template Type"
                        fullWidth
                        value={values.templateType}
                        onChange={handleChange}
                      >
                        <MenuItem value="template1">Template 1</MenuItem>
                        <MenuItem value="template2">Template 2</MenuItem>
                      </TextField>
                      {touched.templateType && errors.templateType && (
                        <FormHelperText error>
                          {errors.templateType}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        select
                        id="fontColor"
                        name="fontColor"
                        label="FontColor Type"
                        fullWidth
                        value={values.fontColor}
                        onChange={handleChange}
                      >
                        <MenuItem value="#a9a9a9">Grey</MenuItem>
                        <MenuItem value="#b0c4de">Light Steel Blue</MenuItem>
                        <MenuItem value="#ba55d3">Purple</MenuItem>
                        <MenuItem value="#ab4e52">Rose Vale</MenuItem>
                      </TextField>
                      {touched.fontColor && errors.fontColor && (
                        <FormHelperText error>
                          {errors.fontColor}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            select
                            id="backgroundColor"
                            name="backgroundColor"
                            label=" Row Background Color"
                            fullWidth
                            value={values.backgroundColor}
                            onChange={handleChange}
                          >
                            <MenuItem value="none">None</MenuItem>
                            <MenuItem value="#f4f7f8">light Blue</MenuItem>
                            <MenuItem value="#f0ead6">Eggshell</MenuItem>
                            <MenuItem value="#e3dac9">Bone</MenuItem>
                            <MenuItem value="#e5e4e2">Platinum</MenuItem>
                          </TextField>
                          {touched.backgroundColor &&
                            errors.backgroundColor && (
                              <FormHelperText error>
                                {errors.backgroundColor}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            select
                            id="fontType"
                            name="fontType"
                            label="Font Type"
                            fullWidth
                            value={values.fontType}
                            onChange={handleChange}
                          >
                            <MenuItem value="Helvetica">Helvetica</MenuItem>
                            <MenuItem value="Courier">Courier</MenuItem>
                          </TextField>
                          {touched.fontType && errors.fontType && (
                            <FormHelperText error>
                              {errors.fontType}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Button
                        className="custom-btn"
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mr: "16px" }}
                      >
                        Submit
                      </Button>
                      <Button variant="outlined" size="large">
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper
                  elevation={0}
                  className="page-container-paper"
                  sx={{ borderRadius: "8px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PDFViewer width="100%" height="500" showToolbar={false}>
                        <MyDocument invoiceData={invoiceData} styles={styles} />
                      </PDFViewer>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>
          {open ? (
            <SnackBar onClose={handleClose} data2={type} data3={message} />
          ) : (
            ""
          )}
        </form>
      )}
    </Formik>
  </>
);
};

export default LayoutPage;
