import { useEffect, useState } from "react";
// material ui
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import "../../Styles/UserformStyle.css";
// third party libraries
import * as Yup from "yup";
import { Formik } from "formik";
import OtpInput from "react18-input-otp";
import axios from "axios";
// project import
import { APP_LOGO } from "../../constant";
import { useLocation, useNavigate } from "react-router-dom";
import common from "../../config.json";
import SnackBar from "../../components/Snackbar";

const ResetOtpform = () => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(60);
  const location = useLocation();
  const navigate = useNavigate();
  let baseUrl = common.BASE_URL + common.USER_API_URL;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    console.log(location.state.phone);
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);


  const handleChanged = (enteredOtp: string) => {
    setOtp(enteredOtp);
  };


  // const HandleByEmail = () => {
  //   navigate("/forgetpassword");
  // };


  const resendOtp = async () => {
    let url:string = baseUrl + `/resetpassword/${location.state.email}`;
    
    try {
      const response = await axios.get(
        url,

        {
          headers: {
            accept: "application/json",
          },
        }
      );
      if (response.data) {
        setOpen(true);
        setType("success");
        setMessage("Your code has been resent.");
        setSeconds(60);
      }
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message ||error.response.statusText);
      // switch (error.response.status) {
      //   case 400:
      //     setMessage(
      //       "Invalid request, " +
      //         error.response.data.message +
      //         " Please review the request and try again."
      //     );
      //     break;
      //   case 401:
      //     setMessage(
      //       "Unauthorized! Please ensure you have the necessary permissions to access the requested resource."
      //     );
      //     break;
      //   case 403:
      //     setMessage("Please review the permissions and try again.");
      //     break;
      //   case 404:
      //     setMessage("The requested resource was not found.");
      //     break;
      //   case 422:
      //     setMessage("Unprocessable Entity," + error.response.data.message);
      //     break;
      //   case 409:
      //     setMessage(error.response.data.message);
      //     break;
      //   default:
      //     setMessage(error.response.statusText || error.response.data.message);
      // }
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const data = {
        email: location.state.email,
        otp: otp,
      };
      const response = await axios.post(baseUrl + "/verifyOtp",
       data
      );
      if (response.data.success) {
        navigate("/newPassword", {
          state: {
            otp: otp,
            email: location.state.email,
          },
        });
      } else {
        setOpen(true);
        setType("error");
        setMessage(response.data.message);
      }
    } catch (error: any) {
      setOpen(true);
      setType("error");
      setMessage(error.response.data.message || error.response.statusText);
      // switch (error.response.status) {
      //   case 400:
      //     setMessage(
      //       "Invalid request, " +
      //         error.response.data.message +
      //         " Please review the request and try again."
      //     );
      //     break;
      //   case 401:
      //     setMessage(
      //       "Unauthorized! Please ensure you have the necessary permissions to access the requested resource."
      //     );
      //     break;
      //   case 403:
      //     setMessage("Please review the permissions and try again.");
      //     break;
      //   case 404:
      //     setMessage("The requested resource was not found.");
      //     break;
      //   case 422:
      //     setMessage("Unprocessable Entity," + error.response.data.message);
      //     break;
      //   case 409:
      //     setMessage(error.response.data.message);
      //     break;
      //   default:
      //     setMessage(error.response.statusText || error.response.data.message);
      // }
    }
  };

  return (
    <Formik
      initialValues={{}}
      validationSchema={Yup.object().shape({})}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ handleSubmit }) => (
        <Container
          className="bg-image"
          maxWidth={false}
          sx={{ height: "100vh" }}
        >
          <Box className="resetCode-form">
            <div>
              <img className="logo-image" src={APP_LOGO} alt="logo"></img>
            </div>
            <div className="top-text1">
              <Typography variant="h5">Two-factor Authentication</Typography>
            </div>
            <form
              className="form-input-box"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    To ensure the security of your account, we require that you
                    verify using a One- Time Password (OTP) sent to your
                    registered {location.state.email ? "email" : "phone"} before
                    we proceed changing your password.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <OtpInput
                    value={otp}
                    onChange={handleChanged}
                    numInputs={6}
                    separator={<span></span>}
                    inputStyle={{
                      width: "50px",
                      height: "50px",
                      margin: "3px",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#000000",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack alignItems="center" direction="row" display="flex">
                    <Button
                      disabled={seconds > 0}
                      onClick={resendOtp}
                      style={{
                        color: seconds > 0 ? "#DFE3E8" : "#38B6FF",
                        textTransform: "none",
                      }}
                    >
                      Resend Code
                    </Button>

                    <span style={{ color: " rgba(0, 0, 0, 0.38)" }}>
                      00:{seconds}
                    </span>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className="custom-btn"
                    variant="contained"
                    fullWidth
                    onClick={handleVerifyOtp}
                  >
                    Confirm
                  </Button>
                </Grid>
                {location.state.email ? (
                  ""
                ) : (
                  // <>
                  //   <Grid item xs={12}>
                  //     <Button
                  //       variant="text"
                  //       sx={{
                  //         textTransform: "none",
                  //         p: 0,
                  //       }}
                  //       onClick={HandleByPhone}
                  //     >
                  //       <Typography
                  //         variant="body1"
                  //         sx={{
                  //           borderBottom: "1px solid #1565C0",
                  //         }}
                  //       >
                  //         Switch to mobile authentication
                  //       </Typography>
                  //     </Button>
                  //   </Grid>
                  // </>
                  <>
                    <Grid item xs={12}>
                      <Button
                        variant="text"
                        sx={{
                          textTransform: "none",
                          p: 0,
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            borderBottom: "1px solid #1565C0",
                          }}
                        >
                          Switch to email authentication
                        </Typography>
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Box>
          {open ? (
            <SnackBar onClose={handleClose} data2={type} data3={message} />
          ) : (
            ""
          )}
        </Container>
      )}
    </Formik>
  );
};

export default ResetOtpform;
