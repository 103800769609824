import { Container, Grid } from "@mui/material";
import "../Styles/PagesStyle/index.css";
import DashBoardTable from "../components/DashBoardTable";


const InvoicesList = () => {
  return (
    <Container maxWidth={false} disableGutters={false}>
      <Grid container spacing={0} sx={{ display: "block" }}>
        <Grid item xs={12} md={12}>
          <DashBoardTable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default InvoicesList;
