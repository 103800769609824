import React from "react";
import { useState } from "react";
import PasswordInputField from "./PasswordField";
import StrengthMeter from "./StrengthMeter";
import { Box, FormHelperText } from "@mui/material";
type passwordProps = {
  data2: any;
  data3: any;
  data: boolean;
  value: string;
  onChange: Function;
  onFocus: Function;
  onBlur: Function;
};

function PasswordStrengthChecker({
  data2,
  data3,
  data,
  value,
  onChange,
  onFocus,
  onBlur,
}: passwordProps) {
  const [poorPassword, setPoorPassword] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [strongPassword, setStrongPassword] = useState(false);
  const [passwordErr, setPasswordErr] = useState("");

  const passwordStrength = (Event: any) => {
    const passwordValue = Event.target.value;
    const passwordLength = passwordValue.length;

    const poorRegExp = /[a-z]/;
    const weakRegExp = /(?=.*?[0-9])/;
    const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
    const noSerialRegExp = /^(?!.*(123|abc|xyz)).*$/i;
  

    const poorPassword = poorRegExp.test(passwordValue);
    const weakPassword = weakRegExp.test(passwordValue);
    const strongPassword =
      strongRegExp.test(passwordValue) && noSerialRegExp.test(passwordValue);
    

   
     
      // to check poor password
      if (
        passwordLength <= 3 &&
        (poorPassword || weakPassword || strongPassword)
      ) {
        setPoorPassword(true);
        setPasswordErr("Weak");
      }
      // to check weak password
      if (
        passwordLength >= 4 &&
        poorPassword &&
        (weakPassword || strongPassword)
      ) {
        setPoorPassword(false);
        setWeakPassword(true);
        setPasswordErr("Moderate");
      } else {
        setWeakPassword(false);
      }
      // to check strong Password
      if (
        passwordLength === 8 &&
        poorPassword &&
        weakPassword &&
        strongPassword
      ) {
        setWeakPassword(false);
        setStrongPassword(true);
        setPasswordErr("Strong");
      } else {
        setStrongPassword(false);
      }
    
  };

  return (
    <Box>
      <PasswordInputField
        handlePasswordChange={onChange}
        handlePasswordFocus={onFocus}
        handlePasswordBlur={onBlur}
        passwordValue={value}
        passwordStrength={passwordStrength}
        passwordError= {data2}
        tounched={data3}
      />
      <StrengthMeter
        poorPassword={poorPassword}
        weakPassword={weakPassword}
        strongPassword={strongPassword}
        passwordError={passwordErr}
      />
      {data && (
        <FormHelperText id="password-helper-text">
          <Box
            sx={{
              border: "2px dashed #E0E0E0",
              borderRadius: "4px",
              mt: "5px",
              mb: "5px",
            }}
          >
            <ul style={{ marginLeft: "-20px" }}>Password must have:</ul>
            <ul style={{ marginTop: "-10px" }}>
              <li>at least one lower case character</li>
              <li>at least one capital letter</li>
              <li> at least one number</li>
              <li> at least eight characters</li>
            </ul>
          </Box>
        </FormHelperText>
      )}
    </Box>
  );
}

export default PasswordStrengthChecker;
