import { useContext, useState } from "react";

// material ui
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// third party libraries
import { Formik } from "formik";
import * as Yup from "yup";
import "../../Styles/PagesStyle/index.css";

// project import
import SnackBar from "../../components/Snackbar";
import { formatInput, formatPhoneNumber, formatToUpper } from "../ValidationPage";
import { CustomerContext } from "./CustomerPage";
import common from '../../config.json';
import axios from "axios";
import image4 from "../../images/Avatar4.png";
import { useAuthUser } from "react-auth-kit";

const EditPage = () => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const { setPage, test, user } = useContext(CustomerContext);
  const authUser: any = useAuthUser();
  const token = authUser()?.token;

  
  const {
    customerCode,
    name,
    phone,
    email,
    address,
    country,
    gstNo,
    state,
    city,
    pinCode,
  } = user;
 
 

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen(false); 
    setPage("");                        
  };

  return (
    <Formik
      initialValues={{
        customerCode: customerCode || "",
        name: name || "",
        phone: phone || "",
        email: email || "",
        address: address || "",
        country: country || "",
        state: state || "",
        city: city || "",
        pinCode: pinCode || "",
        gstNo: gstNo || "",
      }}
      validationSchema={Yup.object().shape({
        customerCode: Yup.string().required("First Name is required"),
        name: Yup.string().required("Name is required"),
        phone: Yup.string()
          .required("Mobile Phone Number is required")
          .min(10)
          .max(10),
        email: Yup.string().email().required("Email is required"),
        address: Yup.string().required("Address Line 1 is required"),
        state: Yup.string().required("Address Line 1 is required"),
        country: Yup.string().required("Address Line 1 is required"),
        city: Yup.string().required("Address Line 1 is required"),
        pinCode: Yup.string()
          .required("pinCode Number required")
          .min(6, "pinCode Number must be at least 6 digits")
          .max(6, "pinCode Number cannot exceed 6 digits"),
        gstNo: Yup.string(),
      })}
      onSubmit={async (values) => {
        console.log(values);
        const data = { ...values };
        let baseUrl = common.BASE_URL + common.CUSTOMERs_API + "/edit";
        try {
          const response = await axios
            .put(baseUrl, data, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then(function (response) {
              if (response.data) {
                setOpen(true);
                setType("success");
                setMessage("You have successfully updated records.");
              }
            });
          console.log(response);
        } catch (error: any) {
          setOpen(true);
          setType("error");
          setMessage(error.response.statusText || error.response.data.message);
          // switch (error.response.status) {
          //   case 400:
          //     setMessage(
          //       "Invalid request, " +
          //         error.response.data.message +
          //         " Please review the request and try again."
          //     );
          //     break;
          //   case 401:
          //     setMessage(
          //       "Unauthorized! Please ensure you have the necessary permissions to access the requested resource."
          //     );
          //     break;
          //   case 403:
          //     setMessage("Please review the permissions and try again.");
          //     break;
          //   case 404:
          //     setMessage("The requested resource was not found.");
          //     break;
          //   case 422:
          //     setMessage("Unprocessable Entity," + error.response.data.message);
          //     break;
          //   case 409:
          //     setMessage(error.response.data.message);
          //     break;
          //   default:
          //     setMessage(
          //       error.response.statusText || error.response.data.message
          //     );
          // }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Container maxWidth={false} disableGutters={false}>
            <Grid container spacing={0} maxWidth="850px">
              <Paper
                elevation={0}
                className="page-container-paper"
                sx={{
                  borderRadius: "16px",
                }}
              >
                <Typography variant="h5" sx={{ pb: "24px" }}>
                  Customer Details
                </Typography>

                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      display="flex"
                      alignItems="center"
                      gap="12px"
                    >
                      <img width="34px" height="34px" src={image4} alt="" />
                      <Typography variant="h6">
                        {customerCode} {name}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="customerCode"
                      label="Customer Code"
                      fullWidth
                      value={formatToUpper(values.customerCode)}
                      name="customerCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(
                        touched.customerCode && errors.customerCode
                      )}
                      disabled
                    />
                    {touched.customerCode && errors.customerCode && (
                      <FormHelperText error id="customerCode-helper-text">
                        {errors.customerCode}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="name"
                      label="Name"
                      fullWidth
                      value={formatInput(values.name)}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="name-helper-text">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="email"
                      label="Email"
                      fullWidth
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error id="email-helper-text">
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="gstNo"
                      label="GST number"
                      fullWidth
                      value={formatToUpper(values.gstNo)}
                      name="gstNo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.gstNo && errors.gstNo)}
                    />
                    {touched.gstNo && errors.gstNo && (
                      <FormHelperText error id="gstNo-helper-text">
                        {errors.gstNo}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="phone"
                      label="Mobile Phone"
                      inputProps={{ maxLength: 10 }}
                      fullWidth
                      value={formatPhoneNumber(values.phone)}
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.phone && errors.phone)}
                    />
                    {touched.phone && errors.phone && (
                      <FormHelperText error id="phone-helper-text">
                        {errors.phone}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="pincode"
                      label="Pincode"
                      fullWidth
                      value={values.pinCode}
                      name="pinCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.pinCode && errors.pinCode)}
                    />
                    {touched.pinCode && errors.pinCode && (
                      <FormHelperText error id="pincode-helper-text">
                        {errors.pinCode}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="address"
                      label="Address Line 1"
                      fullWidth
                      multiline
                      value={formatInput(values.address)}
                      name="address"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.address && errors.address)}
                    />
                    {touched.address && errors.address && (
                      <FormHelperText error id="address-helper-text">
                        {errors.address}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="country"
                      label="Country"
                      fullWidth
                      value={formatInput(values.country)}
                      name="country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.country && errors.country)}
                    />
                    {touched.country && errors.country && (
                      <FormHelperText error id="country-helper-text">
                        {errors.country}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="state"
                      label="state"
                      fullWidth
                      value={formatInput(values.state)}
                      name="state"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.state && errors.state)}
                    />
                    {touched.state && errors.state && (
                      <FormHelperText error id="state-helper-text">
                        {errors.state}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="city"
                      label="City"
                      fullWidth
                      value={formatInput(values.city)}
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.city && errors.city)}
                    />
                    {touched.city && errors.city && (
                      <FormHelperText error id="city-helper-text">
                        {errors.city}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      className="custom-btn"
                      type="submit"
                      size="large"
                      variant="contained"
                      sx={{ mr: "12px" }}
                    >
                      Update
                    </Button>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => {
                        test === 1 ? setPage("profile") : setPage("");
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Container>
          {open ? (
            <SnackBar onClose={handleClose} data2={type} data3={message} />
          ) : (
            ""
          )}
        </form>
      )}
    </Formik>
  );
};

export default EditPage;
