import * as React from "react";
import{Box, Button} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
type openProps = {
  data2:String,
  data3:String,
  onClose: Function;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = ({ onClose,data2,data3 }: openProps)=> {
 
    

  return (
    <Snackbar
      open={true}
      autoHideDuration={3000}
      onClose={() => onClose()}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      sx={{
        width: "100%",
        zIndex: 9999,
      }}
    >
      <Alert
        sx={{ width: "70%" }}
        onClose={() => onClose()}
        severity={data2 === "success" ? "success" : "error"}
        action={
          <Box display="flex" alignItems="center">
            <Button
              variant="text"
              sx={{ color: "#FFFFFF", p: "2px 8px" }}
              endIcon={<Close />}
              onClick={() => onClose()}
            >
              close
            </Button>
          </Box>
        }
      >
        <Typography variant="body2">{data3}</Typography>
      </Alert>
    </Snackbar>
  );
}

export default SnackBar;
