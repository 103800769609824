import * as React from "react";
import { Box, Grid, Paper } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

const pData = [100, 250, 500, 1000, 2000, 5000, 10000];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

export default function DashBoardBarChart() {
  // Define custom colors for the bars
  const customColors = [
    "#128ced",
    "#244D70",
    "#D123B3",
    "#F7E018",
    "#FE452A",
    "#808080",
    "#128ced",
  ];

  const series = [
    {
      data: pData,
      label: "pv",
      id: "pvId",
      // Define custom colors for the bars in this dataset
      colors: customColors,
    },
  ];

  return (
    <Grid item xs={12}>
      <Paper elevation={1} sx={{ p: "16px", borderRadius: "12px" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          alignItems={"center"}
          minHeight="300px"
        >
          <BarChart
            width={600}
            height={300}
            series={series}
            xAxis={[{ data: xLabels, scaleType: "band" }]}
          />
        </Box>
      </Paper>
    </Grid>
  );
}
