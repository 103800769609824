import { createContext, useEffect, useState } from "react";

// material ui
import { Grid } from "@mui/material";

// project import
import {
  AddVendor,
  VendorEditPage,
  VendorView,
  VendorTable,
} from "./index";
import { Data } from "./VendorTable";
import image4 from "../../images/Avatar4.png";
import { useLocation } from "react-router-dom";


interface vendorProps {
  page: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
  test: number;
  setTest: React.Dispatch<React.SetStateAction<number>>;
  addVendor: boolean;
  setAddVendor: React.Dispatch<React.SetStateAction<boolean>>;
  user: Data;
  setUser: React.Dispatch<React.SetStateAction<Data>>;
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
}

export const VendorContext = createContext<vendorProps>({
  page: "",
  setPage: () => {},
  test: 0,
  setTest: () => {},
  addVendor: false,
  setAddVendor: () => {},
  user: {
    id: 0,
    vendorCode: "",
    name: "",
    phone: "",
    email: "",
    gstNo:"",
    address: "",
    country: "",
    state: "",
    pinCode: "",
    city: "",
    status: "false",
    address1:"",
  },
  setUser: () => {},
  rowsPerPage: 5,
  setRowsPerPage: () => {},
});

// ==================== ADMIN SETTINGS PAGE =================== //
const VendorPage = () => {
  const [page, setPage] = useState("");
  const [test, setTest] = useState(0);
  const location = useLocation()
  const [addVendor, setAddVendor] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  

  useEffect(() => {
    if(location.pathname.split("/")[2] === "add_staff") {
      setAddVendor(true)
    }
  },[])
  const [user, setUser] = useState<Data>({
    id:0,
    vendorCode: "",
    name: "",
    phone: "",
    email: "",
    gstNo:"",
    address: "",
    country: "",
    state: "",
    pinCode: "",
    city: "",
    status: "false",
    address1:"",
  });

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: "100%",
      }}
    >
      <Grid item xs={12}>
        <VendorContext.Provider
          value={{
            page,
            setPage,
            test,
            setTest,
            addVendor,
            setAddVendor,
            user,
            setUser,
            rowsPerPage,
            setRowsPerPage,
          }}
        >
          {addVendor ? (
            <AddVendor />
          ) : page === "edit" ? (
            <VendorEditPage />
          ) : page === "profile" ? (
            <VendorView />
          ) : (
            <VendorTable />
          )}
        </VendorContext.Provider>
      </Grid>
    </Grid>
  );
};

export default VendorPage;
