import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
 import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { AuthProvider } from "react-auth-kit";
import refreshApi from "../src/RefreshTokenApi";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <AuthProvider
    authType="cookie"
    cookieDomain={window.location.hostname}
    cookieSecure={window.location.protocol === "https:"}
    authName={"_auth"}
    refresh={refreshApi}
  >
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </AuthProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
