import axios from "axios";
import common from '../config.json'



export const getCustomerNames = async (customerId:any,token:any) => {
  const url = common.BASE_URL + common.CUSTOMERs_API + "/list";
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    console.log(response.data);

    const filteredData = response.data.data.filter(
      (item:any) => item.id === customerId
    );
    console.log(filteredData);
    return filteredData[0].customer_name;
  } catch (error) {
    // Handle errors if any
    console.error("Error fetching customer name:", error);
    return ""; // Return empty string as default name
  }
};
