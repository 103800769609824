
import {
  Container,
  TextField,
  Typography,
  Button,
  Box,
  FormHelperText,
} from "@mui/material";

// third party libraries
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// project import
import "../Styles/UserformStyle.css";
 import { APP_LOGO } from "../constant";
import { AuthState } from "../store/rootReducer";
 import { login } from "../../src/store/auth/actions";
import AlertBar from "../components/Alert";
import { useEffect } from "react";
import { useDispatch} from "react-redux";
import { useAuthUser, useSignIn } from "react-auth-kit";
import jwt_decode from "jwt-decode";
import { RESET_AUTH_DATA } from "../store/auth/actionTypes";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = () => {
  const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser: any = useAuthUser();
    const signIn = useSignIn();
  const authData = useSelector((state: AuthState) => state?.auth);

  const { accessToken, error,refreshToken,expiresIn } = authData;

  useEffect(()=>{
    if (!authUser()?.token) {
      navigate("/login");
    }
    const data = { accessToken, refreshToken, expiresIn };
    if(accessToken){
      getAccessToken(data);
    } 
    

  },[accessToken]);


  const getAccessToken = async(data: any) => {
    console.log(data);
    if (data.accessToken != null) {
      const token = data.accessToken;
      const decoded: any = jwt_decode(token);

      signIn({
        token: data.accessToken,
        expiresIn: data.expiresIn, //time in minutes here
        refreshToken: data.refreshToken,
        refreshTokenExpireIn: 14400,
        tokenType: data.token_type,
        authState: {
          token: data.accessToken,
          id: decoded.userId,
          userName: decoded.userName,
        },
      });
      localStorage.setItem("refreshToken", data.refreshToken);
      await navigate("/homepage");
     
    }
  };

 

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (inputs: any) => {
       try {
         let data: any = { ...inputs };
         console.log(data);
         await(dispatch as any)(login({ ...data }));
       } catch (error) {
         console.error("An error occurred during login:", error);
       }
       

      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }: any) => (
        <Container
          className="bg-image"
          maxWidth={false}
          sx={{ height: "100vh", overflow: "auto" }}
        >
          <Box className="register-form" sx={{ paddingBottom: "15px" }}>
            <div>
              <img className="logo-image" src={APP_LOGO} alt="logo"></img>
            </div>
            <div className="top-text1">
              <Typography variant="h5">Sign into WMSInvoice</Typography>
            </div>
            <form className="form-input-box" onSubmit={handleSubmit} noValidate>
              <TextField
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mt: "16px" }}
              />
              {touched.email && errors.email && (
                <FormHelperText error id="email-helper-text">
                  {errors.email}
                </FormHelperText>
              )}

              <TextField
                required
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{ mt: "16px" }}
              />
              {touched.password && errors.password && (
                <FormHelperText error id="password-helper-text">
                  {errors.password}
                </FormHelperText>
              )}
             

              <div className="bottom-text">
                <Typography variant="caption" display="block">
                  By signing up, you agree and accept our{" "}
                  <span>
                    <Link
                      to="/terms_and_conditions"
                      style={{ color: "#1565C0", textDecoration: "none" }}
                    >
                      Terms and Conditions
                    </Link>
                  </span>{" "}
                  and{" "}
                  <span>
                    <Link
                      to="/privacy_policy"
                      style={{ color: "#1565C0", textDecoration: "none" }}
                    >
                      Privacy Policy.
                    </Link>
                  </span>
                </Typography>
              </div>
              {error != null ? <AlertBar message={error} /> : ""}
              <Button
                className="custom-btn"
                variant="contained"
                sx={{
                  fontSize: "15px",
                  width: "100%",
                  height: "42px",
                }}
                type="submit"
              >
                Login
              </Button>
              <div className="bottom-link">
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontSize: "16px",
                    color: "#595959",
                  }}
                >
                  Create new account ?
                  <span className="login-link">
                    <Button
                      variant="text"
                      onClick={() => navigate("/register")}
                    >
                      Register
                    </Button>
                  </span>
                </Typography>
              </div>
            </form>
          </Box>
        </Container>
      )}
    </Formik>
  );
};

export default LoginPage;

