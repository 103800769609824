import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { ForwardedRef, forwardRef } from "react";

type CardProps = {
  func1: Function;
  func2: Function;
  heading: string;
  description: number;
  buttonName1: string;
  buttonName2: string;
};

const Card = forwardRef(function Card(
  { heading, description, buttonName1, buttonName2, func1, func2 }: CardProps,
  ref: ForwardedRef<HTMLButtonElement>
) { 
  return (
    <Grid item xs={12}>
      <Paper elevation={1} sx={{ p: "16px", borderRadius: "12px" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          minHeight="150px"
        >
          <Typography
            variant="h5"
            sx={{ fontSize: "16px", textTransform: "uppercase" }}
          >
            {heading}
          </Typography>
          <Typography
            variant="h5"
            sx={{ fontSize: "24px", textTransform: "uppercase" }}
          >
            {description}
          </Typography>
          <span>
            {" "}
            <Typography
              variant="h4"
              sx={{ fontSize: "12px", textTransform: "uppercase" ,color:"green" }}
              color ="success"
            >
             +19 % last month
            </Typography>
          </span>

          <Stack display="flex" direction="row" spacing={2}>
            <Button
              sx={{ fontSize: "16px" }}
              disableTouchRipple
              disabled={
                buttonName1 === "view reports" ||
                buttonName1 === "view templates"
                  ? true
                  : false
              }
              ref={ref}
              onClick={() => func2()}
            >
              {buttonName1}
            </Button>
            <Button
              sx={{ fontSize: "16px" }}
              disableTouchRipple
              disabled={
                buttonName2 === "create report" ||
                buttonName2 === "add templates"
                  ? true
                  : false
              }
              ref={ref}
              onClick={() => func1()}
            >
              {buttonName2}
            </Button>
          </Stack>
        </Box>
      </Paper>
      {/* <Paper elevation={0} sx={{ p: "24px", borderRadius: "16px" }}>
        <Typography variant="h5" component="h4">
          {heading}
        </Typography>
        <Box display="Block"  height="125px" sx={{ py: "30px" }}>
          <Typography variant="h6" color="primary">
            {description}
          </Typography>
        </Box>
        <Stack display="flex" direction="row" spacing={2}>
          <Button disabled={buttonName1 === "view reports"|| buttonName1 === "view templates" ?true: false} ref={ref} onClick={() => func2()}>
            {buttonName1}
          </Button>
          <Button disabled={buttonName2 === "create report"|| buttonName2 === "add templates" ?true: false} ref={ref} onClick={() => func1()}>
            {buttonName2}
          </Button>
        </Stack>
      </Paper> */}
    </Grid>
  );
});

export default Card;
