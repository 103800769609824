import React, { useContext, useState } from "react";

// material ui
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

// third party libraries
import { Formik } from "formik";
import * as Yup from "yup";
import "../../Styles/PagesStyle/index.css";

// project import
import SnackBar from "../../components/Snackbar";
import {formatInput, formatToUpper } from "../ValidationPage";
import { ProductContext } from "./ProductPage";
import common from "../../config.json";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";



// ======================== ADMIN NEW STAFF PAGE ===================== //
const AddStaff = () => {
    const navigate: any = useNavigate();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("success");
  const [message, setMessage] = useState("");
  const authUser:any = useAuthUser();
  const token = authUser()?.token;

  const { setAddProduct, setPage, setTest, setUser, user } =
    useContext(ProductContext);



  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickAway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Formik
      initialValues={{
        productCode: "",
        name: "",
        price: "",
        brand: "",
        category: "",
        description: "",
        hsnCode: "",
      }}
      validationSchema={Yup.object().shape({
        productCode: Yup.string().required("First Name is required"),
        name: Yup.string().required("Name is required"),
        price: Yup.string().required("Price is required").min(1),
        brand: Yup.string().required("Brand is required"),
        category: Yup.string().required("Category is required"),
        description: Yup.string().required("Description is required"),
        hsnCode: Yup.string().required("Description is required"),
      })}
      onSubmit={async (values, action) => {
        console.log(values);
        let baseUrl = common.BASE_URL + common.PRODUCT_API + "/add";

        try {
          const response = await axios
            .post(baseUrl, values, {
              headers: {
                Authorization: "Bearer " + token,
              },
            })
            .then(function (response) {
              if (response.data) {
                setOpen(true);
                setType("success");
                setMessage("You have successfully added new Product.");
              }
              action.resetForm();
              setPage("profile");
              setUser({ ...user, ...values });
              setTest(0);
              setAddProduct(false);
            });
          console.log(response);
        } catch (error: any) {
          setOpen(true);
          setType("error");
          setMessage(error.response.data.message || error.response.statusText);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Container maxWidth={false} disableGutters={false}>
            <Grid container spacing={0} maxWidth="850px">
              <Paper
                elevation={0}
                className="page-container-paper"
                sx={{
                  borderRadius: "16px",
                }}
              >
                <Button
                  size="large"
                  onClick={() => setAddProduct(false)}
                  sx={{ mb: "12px", p: "8px 0px" }}
                >
                  Manage Products
                </Button>
                <Typography variant="h5" sx={{ pb: "24px" }}>
                  New Products
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="productCode"
                      label="Product Code"
                      fullWidth
                      value={formatToUpper(values.productCode)}
                      name="productCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.productCode && errors.productCode)}
                    />
                    {touched.productCode && errors.productCode && (
                      <FormHelperText error id="productCode-helper-text">
                        {errors.productCode}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      id="name"
                      label="Name"
                      fullWidth
                      value={formatInput(values.name)}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.name && errors.name)}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText error id="name-helper-text">
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="price"
                      label="Price"
                      fullWidth
                      value={values.price}
                      name="price"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.price && errors.price)}
                    />
                    {touched.price && errors.price && (
                      <FormHelperText error id="price-helper-text">
                        {errors.price}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="brand"
                      label="Brand"
                      inputProps={{ maxLength: 10 }}
                      fullWidth
                      value={formatInput(values.brand)}
                      name="brand"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.brand && errors.brand)}
                    />
                    {touched.brand && errors.brand && (
                      <FormHelperText error id="brand-helper-text">
                        {errors.brand}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="category"
                      label="Category"
                      fullWidth
                      value={formatInput(values.category)}
                      name="category"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.category && errors.category)}
                    />
                    {touched.category && errors.category && (
                      <FormHelperText error id="category-helper-text">
                        {errors.category}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      required
                      id="hsnCode"
                      label="HSN code"
                      fullWidth
                      value={formatToUpper(values.hsnCode)}
                      name="hsnCode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.hsnCode && errors.hsnCode)}
                    />
                    {touched.hsnCode && errors.hsnCode && (
                      <FormHelperText error id="hsnCode-helper-text">
                        {errors.hsnCode}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="description"
                      label="Description"
                      fullWidth
                      multiline
                      value={formatInput(values.description)}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.description && errors.description)}
                    />
                    {touched.description && errors.description && (
                      <FormHelperText error id="description-helper-text">
                        {errors.description}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      className="custom-btn"
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mr: "16px" }}
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        navigate("/products");
                        setAddProduct(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Container>
          {open ? (
            <SnackBar onClose={handleClose} data2={type} data3={message} />
          ) : (
            ""
          )}
        </form>
      )}
    </Formik>
  );
};

export default AddStaff;
